
/* ---- Text level elements ---- */
abbr[title] {
    border-bottom: 1px dotted #c6bfbf;
    cursor: help;
}

b, strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

ins {
    background: rgba(0, 0, 0, .07);
    color: darken($color-primary, 10%);
    text-decoration: none;
}

mark {
    display: inline-block;
    line-height: normal;
    background-color: rgba($color-utility-selection, 75%);
    color: darken($color-primary, 10%);
    font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
    border-radius: 2px;
    padding: 0.125em 0.25em;
}

pre,
code,
kbd,
samp {
    font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
    color: #484040;
    background: #efefef;
    background: rgba(0, 0, 0, .07);
    padding: 2px;
    margin: 0;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

small {
    font-size: 1.28rem;
}
