.breadcrumb {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;
    font-size: $fs-small;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: $color-grey-04;

    @include screen-560 {
        flex-wrap: nowrap;
        justify-content: center;
    }

    @include screen-992 {
        font-size: $fs-body;
    }

    > li {
        flex: 0 0 auto;
        display: block;
        line-height: normal;
        border-left: 1px solid $color-grey-04;

        &:first-child {
            border-left: none;
        }

        span {
            display: inline-block;
            padding: 2px 12px;
        }
    }

    a {
        position: relative;
        text-decoration: none;
        color: $color-00;
        z-index: 1;
        display: block;
        padding: 2px 12px;
        line-height: normal;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            right: 50%;
            top: 0;
            bottom: 0;
            background-color: transparent;
            transition:
                left 250ms ease,
                right 250ms ease,
                background-color 250ms ease;
            z-index: -1;
        }

        &:hover,
        &:focus {
            color: $color-white;

            &:before {
                background-color: $color-grey-04;
                left: 0;
                right: 0;
            }
        }
    }
}

@media screen and (max-width: $bp559) {
    .breadcrumb {
        margin-top: 20px;
        margin-bottom: 10px;
        line-height: 1.4;
    }
    .breadcrumb > li:first-child,
    .breadcrumb > li:last-child {
        // hide home and current page
        display: none;
    }
}
