.field-element--upload .field-input input[type="file"] {
    border: 1px solid transparent;
    border-radius: $standard-radius;
    background-color: transparent;
}

input[type="file"]:focus {
    outline: $color-grey-06 auto 5px;
}
input[type="file" i]:focus {
    outline-offset: 0;
}

.field-element--upload,
.field-element--fileupload {
    input[type="file"]::file-selector-button {
        @include button;
        @include button-width-sm;
    }

    /* Chrome, Edge & Safari */
    input[type="file"]::-webkit-file-upload-button {
        @include button;
        @include button-width-sm;
    }

    input[type="file"]:hover::-webkit-file-upload-button {
        background-color: $color-00;
        border-color: $color-00;
    }
    input[type="file"]:focus::-webkit-file-upload-button {
        background-color: $color-00;
        outline: none;
    }
    input[type="file"]:active::-webkit-file-upload-button {
        outline: none;
        background-color: $color-00;
    }
}


/* ---- white ---- */
.field-element--white input[type="file"]::-webkit-file-upload-button {
    background-color: $color-white;
}
.field-element--white input[type="file"]:hover::-webkit-file-upload-button {
    background-color: $color-grey-02;
    border-color: darken($color-grey-04, 10%);
}
.field-element--white input[type="file"]:focus::-webkit-file-upload-button {
    background-color: $color-grey-02;
}
.field-element--white input[type="file"]:active::-webkit-file-upload-button {
    background-color: $color-grey-03;
}

.field-element--fileupload {
    input {
        line-height: 35px;
        width: 100%;
    }
}
