.submit-bar {
    text-align: right;
    background: $color-grey-01;
    padding: $form-spacing*2;
    margin: $form-spacing*2 0;
}

.sidebar .submit-bar {
    padding: $form-spacing*1.5 0;
}

.submit-bar a {
    margin-right: $form-spacing*2.5;
}

.submit-bar--bleed-left {
    @include bleed-left($color-grey-01);
}

.submit-bar--bleed-right {
    @include bleed-right($color-grey-01);
}
