
.button {
    @include button;
}

.button--no-chevron {
    &:after {
        content: none;
    }
}

/* Button on reverse */
.reverse-text {
    .button {
        color:#fff;
        background-color: $color-03;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($color-03, 10%);
            color: $color-white;
            opacity: 1;
        }
    }
}

/* - Button icons - */
.button__icon {
    display: inline-block;
    vertical-align: top;
    width: $spacing--medium;
    height: $spacing--medium;
}


.button__icon--right {
    margin-left: $spacing--xsmall;
    margin-right: -($spacing--xxsmall);
}

.button__icon--left {
    margin-right: $spacing--xsmall;
    margin-left: -($spacing--xxsmall);
}

.button__icon svg {
    width: 16px;
    height: 16px;
}

.button__icon svg path {
    fill: $color-white;
    transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
    fill: $color-primary;
}

.button__icon--secondary svg path {
    fill: $color-00;
}

.button:hover .button__icon--light-purple svg path {
    fill: $color-white;
}


/* - Button styles - */
/* Alternative */
.button--alt {
    @include button-alt;
}

/* Secondary */
.button--secondary {
    @include button-secondary;
}

/* Red Warning Button */
.button--warning {
    @include button-warning;
}

/* Grey */
.button--neutral {
    @include button-neutral;
}

/* Green */
.button--positive {
    @include button-positive;
}

.button--grey {
    @include button-grey;
}

/* Block */
.button--block {
    display: block;
    width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
    border: none;
    opacity: 0.4;
    cursor: default;
    -webkit-box-shadow: none;
            box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

/* Extra Small */
.button--xsmall,
.button.button--xsmall {
    padding: 6px 9px;
    font-size: $fs-small;
}

/* Small */
.button-small,
.button--small {
    font-size: $fs-body;
    line-height: 1;
    padding: 8px calc(12px - 0.25em) 8px 12px;

    @include screen-768 {
        padding: 8px $spacing*1.5 $spacing;
    }
}

/* BP medium */
@include screen-992 {
    /* Large */
    .button-large,
    .button--large {
        font-size: 2.4rem;
        padding: $spacing--medium $spacing--xlarge;
    }

    .button--large .button__icon {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon svg {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon--right {
        margin-left: $spacing--xsmall;
        margin-right: -($spacing);
    }

    .button--large .button__icon--left {
        margin-right: $spacing--xsmall;
        margin-left: -($spacing);
    }
}

.text-link {
    @include text-button;
}
