.box {
    @include box;
}

.box--tiny {
    @include box('tiny');
}

.box--small {
    @include box('small');
}

.box--large {
    @include box('large');
}

.box-lined {
    @include box-lined;
}

.box-lined--grey {
    @include box-lined($color-grey-06);
}

.box-lined--00 {
    @include box-lined($color-00);
}

.box-lined--01 {
    @include box-lined($color-01);
}

.box-lined--02 {
    @include box-lined($color-02);
}

.box-lined--03 {
    @include box-lined($color-03);
}

.box-lined--warning {
    @include box-lined($color-utility-alert-subtle);
}

.box-lined--error {
    @include box-lined($color-utility-warning-subtle);
}

.box-lined--positive{
    @include box-lined($color-utility-positive-subtle);
}


.box-lined-bottom {
    @include box-lined($accentBorder: 'bottom');

    &.box-lined--grey {
        @include box-lined($color-grey-06, 'bottom');
    }

    &.box-lined--00 {
        @include box-lined($color-00, 'bottom');
    }

    &.box-lined--01 {
        @include box-lined($color-01, 'bottom');
    }

    &.box-lined--02 {
        @include box-lined($color-02, 'bottom');
    }

    &.box-lined--03 {
        @include box-lined($color-03, 'bottom');
    }
}

.box-lined-top {
    @include box-lined($accentBorder: 'top');

    &.box-lined--grey {
        @include box-lined($color-grey-06, 'top');
    }

    &.box-lined--00 {
        @include box-lined($color-00, 'top');
    }

    &.box-lined--01 {
        @include box-lined($color-01, 'top');
    }

    &.box-lined--02 {
        @include box-lined($color-02, 'top');
    }

    &.box-lined--03 {
        @include box-lined($color-03, 'top');
    }
}


.box.box--x0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
