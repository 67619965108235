
.site-messages {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        display: block;
        margin: 0 0 15px;
        padding: 10px 20px;
        vertical-align: bottom;
        text-align: left;
        font-size: $fs-body;
        line-height: 1.2em;

        &:last-child {
            margin-bottom: 0;
        }

        &.site-messages__item--mb {
            margin-bottom: $fs-body;
        }
    }
}

.site-messages .error,
.site-messages__item.error {
    background: $color-utility-warning;
    border-left: 4px solid $color-utility-warning-subtle;
    color: #FFF !important;
}

.site-messages .confirm,
.site-messages__item.confirm {
    color: #FFF !important;
    background: $color-utility-positive;
    border-left: 4px solid $color-utility-positive-subtle;
}

.site-messages .warning,
.site-messages__item.warning {
    color: #FFF !important;
    background: $color-utility-alert;
    border-left: 4px solid $color-utility-alert-subtle;
}


.site-messages .attention,
.site-messages__item.attention {
    color: #FFF !important;
    background: darken($color-04, 15%);
    border-left: 4px solid  $color-04;
}

.site-messages a {
    color: #FFF;
}


.site-messages--light {
    .site-messages__item {
        background-color: transparent;
        color: $color-grey-06 !important;
        padding-top: 0;
        padding-bottom: 0;

        .site-messages__item__heading {
            @include subtitle();
            display: block;
        }

        &.error {
            border-left-color: $color-utility-warning-subtle;

            .site-messages__item__heading {
                color: $color-utility-warning-subtle;
            }
        }

        &.confirm {
            border-left-color:  $color-utility-positive-subtle;

            .site-messages__item__heading {
                color: $color-utility-positive-subtle;
            }
        }

        &.warning {
            border-left-color: $color-utility-alert-subtle;

            .site-messages__item__heading {
                color: $color-utility-alert-subtle;
            }
        }
    }
}
