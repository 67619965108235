/* fieldset styles */
fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.field-element--checkboxlist--columns .field-element__input-set {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
}

/* Hide checkbox and radio field element) */
.field-element input[type="checkbox"],
.field-element input[type="radio"] {
    @include vis-hidden;
}

@mixin fieldsetLabel {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label,
.field-element--small input[type="checkbox"] + label,
.field-element--small input[type="radio"] + label {
    @include fieldsetLabel;
}

@mixin fieldsetInput {
    display: inline-block;
    width: $form-spacing*3;
    height: $form-spacing*3;
    position: relative;
    top: $form-spacing/2;
    margin-left: -$form-spacing*4;
    @include form-textbox-styles;
}

/* -- Field set pseudo elements -- */
.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before,
.field-element--small input[type="checkbox"] + label:before,
.field-element--small input[type="radio"] + label:before {
    content: " ";
    @include fieldsetInput;
}

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before,
.field-element--small input[type="radio"] + label:before {
    border-radius: 100%;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    left: 1px;
    top: $form-spacing*0.75;
}

@mixin fieldsetInputCheckboxChecked {
   background-image: url(../assets/icon-system/icon_form_tick-md.svg);
   background-position: left top;
   background-repeat: no-repeat;
   background-size: 90%;
   width: $form-spacing*3;
   height: $form-spacing*3;
}

/* Checkbox box checked (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after {
    content: " ";
    @include fieldsetInputCheckboxChecked;
}

@mixin fieldsetInputRadioChecked {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: $color-grey-07;
 }

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    @include fieldsetInputRadioChecked;
}

/* White */
.field-element--white input[type="checkbox"] + label:before,
.field-element--white input[type="radio"] + label:before {
    background-color: $color-white;
}

/* input focus state */
.fieldset--multiradio:focus-within {
    outline: $color-grey-02 dashed 1px;
}
.field-element input[type="checkbox"]:focus + label {
    outline: $color-grey-06 auto 5px;
    outline-offset: -3px;
}

/* ------------------------
Fieldsets styles where
the label is not a sibling of the input
the label wraps the input
--------------------------- */
.checkbox__label--wrap {
    position: relative;
    cursor: pointer;
    display: block;

    .pseudo-input {
        @include fieldsetInput;
        margin-left: 0;

        &:before {
            content: none;
        }
    }

    .label-body {
        padding-left: 0.2em;
    }

    input[type=checkbox]:checked + .pseudo-input:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include fieldsetInputCheckboxChecked;
    }
}

.radio__label--wrap {
    position: relative;
    cursor: pointer;
    display: block;

    .pseudo-input {
        @include fieldsetInput;
        margin-left: 0;
        border-radius: 100%;

        &:before {
            content: none;
        }
    }

    .label-body {
        padding-left: 0.2em;
    }

    input[type="radio"]:checked + .pseudo-input:before {
        content: " ";
        position: absolute;
        @include fieldsetInputRadioChecked;
    }
}

/* ---- Field errors ---- */
.field-element.field-element--error input[type="checkbox"] + label:before,
.field-element.field-element--error input[type="radio"] + label:before {
    background-color: lighten($color-utility-warning, 62%);
    border-color: lighten($color-utility-warning, 58%);
}

.field-element.field-element--warning input[type="checkbox"] + label:before,
.field-element.field-element--warning input[type="radio"] + label:before {
    background-color: lighten($color-utility-alert, 62%);
    border-color: lighten($color-utility-alert, 58%);
}

/* ---- Field errors ---- */
.field-element.field-element--error {
    label {
        color: $color-utility-warning;
    }

    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        background-color: lighten($color-utility-warning, 62%);
        border-color: lighten($color-utility-warning, 58%);
    }

    .pseudo-input {
        background-color: lighten($color-utility-warning, 62%);
        border-color: lighten($color-utility-warning, 58%);
    }
}

.field-element.field-element--warning {
    label {
        color: $color-utility-alert;
    }

    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        background-color: lighten($color-utility-alert, 62%);
        border-color: lighten($color-utility-alert, 58%);
    }

    .pseudo-input {
        background-color: lighten($color-utility-alert, 62%);
        border-color: lighten($color-utility-alert, 58%);
    }
}
