
* + .archive-links {
	margin-top: 20px;
}
.archive-links__title {
	.section-title {
		margin-bottom: 0;
	}
}
.archive-links__list {
	list-style-type: none;
	padding: 0;
	margin-bottom: 0;
	line-height: normal;

	&__item {
		padding-top: 8px;
		padding-bottom: 8px;

		&__link {
			display: block;
			color: $color-grey-05;
			text-decoration: none;
			transition: transform $link-transition;

			&:hover,
			&:focus,
			&:active {
				color: $color-00;
			}

			&:after {
				content: "\003e";
			}
		}
	}

	&-depth0 {
		margin-bottom: 16px;
		border-left: 3px solid $color-grey-04;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;

		> li {
			font-size: inherit;
			margin-top: 0;
			margin-left: 16px;
		}
	}

	&-depth1 {
		margin-top: 0;
		list-style-type: none;
		font-size: 90%;

		@include screen-560 {
			font-size: $fs-body;
		}

		> li {
			margin-left: 16px;

			> a {
				display: inline-block;
			}
		}
	}

	&-depth2 {
		margin-left: 16px;
		font-size: $fs-small;

		@include screen-560 {
			font-size: 90%;
		}
	}
}
