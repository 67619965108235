.menu-search-field {
    display: flex;
    align-items: center;

    .field-element--text {
        position: relative;
        flex: 0 1 140px;
        max-width: 140px;
        margin: 0;

        @include screen-992() {
            display: none;
        }

        @include screen-1400() {
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 50%;
            right: 50%;
            bottom: 0;
            height: 1px;
            background-color: $color-grey-07;
            transition: left 250ms ease, right 250ms ease;
        }

        &:hover {
            &:before {
                left: 5px;
                right: 0.25em;
            }
        }

        &:focus-within {
            &:before {
                left: 5px;
                right: 0.25em;
            }
        }

        input {
            background: transparent;
            border: none;
            font-family: $primary-font;
            font-size: $fs-body;
            padding: 5px 0 4px 5px;
            line-height: 1.2;
            width: 100%;

            &:focus {
                outline: 1px dashed $color-grey-04;
                outline-offset: -3px;

                @supports(display: block) {
                    /*
                        supports / focus within hack
                        IE doesn't support focus within
                        It also doesn't support @supports
                    */
                    outline: none;
                }
            }
        }
    }

    button {
        flex: 0 0 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background: $color-white;
        border: none;
        cursor: pointer;
        padding: 0;

        svg {
            display: block;
        }

        &:hover,
        &:focus {
            svg {
                opacity: 1;
            }
        }
    }

    .menu-search-field__button--end {
        @include screen-992() {
            display: none;
        }

        @include screen-1400() {
            display: block;
        }

        svg {
            opacity: 0.5;
        }
    }

    .field-element--text:focus-within + .menu-search-field__button--end svg {
        opacity: 1
    }

    &.shrink-search-field {
        .field-element--text {
            @include screen-1400() {
                display: none;
            }
        }

        .menu-search-field__button--end {
            @include screen-1400() {
                display: none;
            }
        }
    }
}

/** Mobile menu only
===================================*/
@media all and (max-width: $bp991) {
    .menu-search-field {

        .menu-search-field__button--start {
            flex: 0 0 27px;
            height: 45px;

            svg {
                opacity: 1;
            }
        }

        .field-element--text {
            flex: 1 1 auto;
            max-width: calc(100% - 45px);

            input {
                padding-left: 9px;
            }
        }

        .menu-search-field__button--end {
            flex: 0 0 27px;
            height: 45px;
            justify-content: flex-end;
        }
    }
}
