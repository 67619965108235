
.section-header {
    @include screen-560 {
        text-align: center;
    }

    &__heading {
        display: block;
        line-height: normal;
    }

    &__subtitle {
        position: absolute;
        left: -15px;
        top: 0;
        @include subtitleBlock($color-01);

        &.subtitle--00 {
            background-color: $color-00;
        }

        &.subtitle--01 {
            background-color: $color-01;
        }

        &.subtitle--02 {
            background-color: $color-02;
        }

        &.subtitle--03 {
            background-color: $color-03;
        }

        @include screen-992 {
            left: -21px;
        }
    }

    &__title {
        display: block;
        position: relative;
        padding-top: 36px;
        @include title-display;

        @include screen-560 {
            display: inline-block;
        }

        @include screen-992 {
            padding-top: 42px;
        }
    }

    &--spacer {
        margin-bottom: $fs-body*1.8;

        @include screen-560 {
            margin-bottom: $fs-body*2.4;
        }

        @include screen-992 {
            margin-bottom: $fs-body*4;
        }

        @include screen-1400 {
            margin-bottom: $fs-body*4.8;
        }
    }

    &__introduction {
        text-align: center;
        font-size: $fs-body*1.2;
        max-width: 800px;
        margin: 0 auto;
        margin-top: $fs-body*1.8;

        @include screen-560 {
            margin-top: $fs-body*2.4;
        }

        @include screen-1200 {
            font-size: $fs-body*1.4;
        }
    }

    &__title-append {
        display: block;
        font-size: $fs-body;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        font-weight: normal;
        margin-top: 0.4em;
        line-height: normal;

        @include screen-560 {
            font-size: 40%;
        }

        @include screen-992 {
            font-size: $fs-body*2.4;
        }
    }
}



.section-subheader {
    margin-bottom: $fs-body;

    @include screen-560 {
        margin-bottom: $fs-body*1.8;
    }

    @include screen-768 {
        margin-bottom: $fs-body*2.4;
    }

    @include screen-560 {
        text-align: center;
    }

    &__heading {
        display: block;
        line-height: normal;
        margin-bottom: 0.2em;
    }

    &__subtitle {
        position: absolute;
        left: -15px;
        top: 0;
        @include subtitleBlock($color-01);

        &.subtitle--00 {
            background-color: $color-00;
        }

        &.subtitle--01 {
            background-color: $color-01;
        }

        &.subtitle--02 {
            background-color: $color-02;
        }

        &.subtitle--03 {
            background-color: $color-03;
        }

        @include screen-992 {
            left: -21px;
        }
    }

    &__title {
        display: block;
        position: relative;
        padding-top: 36px;
        color: $color-primary;
        font-size: $fs-body*2;

        @include screen-560 {
            font-size: $fs-body*2.4;
            display: inline-block;
        }

        @include screen-768 {
            font-size: $fs-body*3;
        }

        @include screen-992 {
            font-size: $fs-body*4;
            padding-top: 42px;
        }

        &.sans-subtitle {
            padding-top: 0;
        }
    }
}

.heading-multipart {
    margin-bottom: 36px;

    @include screen-560 {
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
    }

    &__heading {
        line-height: 1;
        display: inline-block;
    }

    span {
        display: block;
        margin-bottom: 0.2em;
    }

    &__pretitle {
        font-size: $fs-body*1.2;
        margin-left: -15px;

        @include screen-560 {
            font-size: $fs-body*1.4;
        }

        @include screen-992 {
            margin-right: -21px;
        }
    }

    &__title {
        display: block;
        position: relative;
        color: $color-primary;
        font-size: $fs-body*2;

        @include screen-560 {
            font-size: $fs-body*2.4;
            display: inline-block;
        }

        @include screen-768 {
            font-size: $fs-body*3;
        }

        @include screen-992 {
            font-size: $fs-body*4;
        }
    }

    &__posttitle {
        font-size: $fs-body*1.4;
        text-align: right;
        margin-right: -15px;

        @include screen-560 {
            font-size: $fs-body*1.4;
        }

        @include screen-992 {
            margin-right: -21px;
        }
    }
}

.section-header.hide-title {
    .section-header__heading {
        @include vis-hidden;
    }

    .breadcrumb {
        margin-top: 0;
    }
}
