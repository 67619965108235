/* ---- Unpadded row ---- */
.row-gap-0 {
    margin-right: 0;
    margin-left: 0;

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12,
    > .col-xs-grow,
    > .col-xs-shrink {
        padding-right: 0;
        padding-left: 0;
    }
}

/* ---- Small padded row ---- */
// half column width gap
.row-gap-sm {
    @include screen-768 {
        margin-right: -15px;
        margin-left: -15px;
    }

    @include screen-1600 {
        margin-right: -18px;
        margin-left: -18px;
    }

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12,
    > .col-xs-grow,
    > .col-xs-shrink {

        @include screen-768 {
            padding-right: 15px;
            padding-left: 15px;
        }

        @include screen-1600 {
            padding-right: 18px;
            padding-left: 18px;
        }
    }
}


/* ---- Medium padded row ---- */
// half column width gap
.row-gap-md {
    margin-right: -1.83333333vw;
    margin-left: -1.83333333vw;

    @include screen-768 {
        margin-right: -1.91666667vw;
        margin-left: -1.91666667vw;
    }

    @include screen-1600 {
        margin-right: -30px;
        margin-left: -30px;
    }

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12,
    > .col-xs-grow,
    > .col-xs-shrink {
        // container width: 88vw
        // 88vw/24 = 3.6666666667
        padding-right: 1.83333333vw;
        padding-left: 1.83333333vw;

        @include screen-768 {
            padding-right: 1.91666667vw;
            padding-left: 1.91666667vw;
        }

        @include screen-1600 {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}

/* ---- Large padded row ---- */
// 2/3 column width gap
.row-gap-lg {
    margin-right: -2.4444444vw;
    margin-left: -2.4444444vw;

    @include screen-768 {
        margin-right: -2.555555vw;
        margin-left: -2.555555vw;
    }

    @include screen-1600 {
        margin-right: -40px;
        margin-left: -40px;
    }

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12,
    > .col-xs-grow,
    > .col-xs-shrink {
        // container width: 88vw
        // 88vw/32 = 2.444444444vw
        padding-right: 2.444444vw;
        padding-left: 2.444444vw;

        @include screen-768 {
            padding-right: 2.555555vw;
            padding-left: 2.555555vw;
        }

        @include screen-1600 {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

.row-gap-col {
    // column width gap
    // container width: 88vw
    // 88vw/12 = 7.3333333vw
    margin-right: -3.66666667vw;
    margin-left: -3.66666667vw;

    @include screen-768 {
        // container width: 92vw
        // 92vw/12 = 7.66666667vw
        margin-right: -3.83333333vw;
        margin-left: -3.83333333vw;
    }

    @include screen-1600 {
        // container width: 1440px
        // 1440px/12 = 120
        margin-right: -60px;
        margin-left: -60px;
    }

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12,
    > .col-xs-grow,
    > .col-xs-shrink {
        padding-right: 3.66666667vw;
        padding-left: 3.66666667vw;

        @include screen-768 {
            padding-right: 3.83333333vw;
            padding-left: 3.83333333vw;
        }

        @include screen-1600 {
            padding-right: 60px;
            padding-left: 60px;
        }
    }
}
