.sitemap {
    text-align: left;

    @include screen-768 {
        columns: 2;
    }

    @include screen-992 {
        columns: 3;
    }

    &__section {
        break-inside: avoid;

        &:first-child .sitemap__list-title {
            margin-top: 0;
        }
    }

    &__list-title {
        @include title-style-03;
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li:first-child > a {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        > li:last-child > a {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .sitemap-item button {
        display: block;
        width: 100%;
        background: none;
        outline: none;
        border: none;
        font-family: $primary-font;
        text-align: left;
        padding-left: 1.25em;
    }

    .sitemap-item a,
    .sitemap-item button {
        text-decoration: none;
        display: block;
        line-height: 2;
        background-color: transparent;
        color: $color-grey-06;
        border: 1px solid $color-grey-01;
        border-left: 3px solid $color-grey-04;
        margin-bottom: -1px;
        transition:
            color $link-transition,
            border-color $link-transition,
            background-color $link-transition;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-grey-01;
            color: $color-00;
            border-left: 3px solid $color-00;
        }
    }

    .sitemap-item-depth1 a {
        padding-left: 1.25em;
    }

    .sitemap-item-depth2 a {
        padding-left: 2.5em;
    }

    .sitemap-item-depth3 a {
        padding-left: 3.75em;
    }
}
