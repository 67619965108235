
table {
    border-collapse: collapse;
    border: 1px solid $color-grey-02;
}

th,
td {
    padding: 5px 10px;
    border: 1px solid $color-grey-02;
}

th,
thead td {
    background: $color-grey-01;
}


.table-striped {
    border: 1px solid transparent;

    th,
    td {
        padding: 12px;
        border: 1px solid transparent;
    }

    tr:nth-child(even) {
        background-color: $color-white;
    }

    tr:nth-child(odd) {
        background-color: $color-grey-01;
    }
}

.table-naked {
    border-color: transparent;

    th,
    td {
        padding: 8px 12px;
        background-color: transparent;
        border: 1px solid transparent;
        border-bottom-color: $color-grey-01;
    }

    tr {
        &:first-child {
            th,
            td {
                border-top: 1px solid $color-grey-01;
            }
        }
    }
}

.table-guts {
    border-color: transparent;

    th,
    td {
        padding: 8px 12px;
        background-color: transparent;
        border: 1px solid transparent;
        border-bottom-color: $color-grey-01;
    }

    th,
    td {
        border-right-color: $color-grey-01;
    }

    tr th:last-child,
    tr td:last-child {
        border-right-color: transparent;
    }

    tr {
        &:last-child {
            th,
            td {
                border-bottom-color: transparent;
            }
        }
    }
}

.table-invisible {
    border-color: transparent;

    th,
    td {
        padding: 8px 12px;
        background-color: transparent;
        border: 1px solid transparent;
    }
}

.no-thead {
    th {
        text-align: left;
        background: transparent;
        font-family: $accent-font;
    }
}
