/* ---- Bottom margin for stacked columns ---- */
.col-stack {

    &-lh {
        margin-bottom: 1.6rem;
    }

    &-sm {
        margin-bottom: 24px;
    }

    &-md {
        margin-bottom: 40px;
    }

    &-lg {
        margin-bottom: 7.3333334vw;

        @media screen and (min-width: 48em) { /* 768px */
            margin-bottom: 7.3333334vw;
        }

        @media screen and (min-width: 87.5em) {/* 1400px */
            margin-bottom: 53.33333333px;
        }

        @media screen and (min-width: 100em) {/* 1600px */
            margin-bottom: 60px;
        }
    }
}

.col-unstack {
    /* BP Smaller */
    @media screen and (min-width: 36em) { /* 576px */
        &--xsm {
            margin-bottom: 0;
        }
    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */
        &--sm {
            margin-bottom: 0;
        }
    }

    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        &--md {
            margin-bottom: 0;
        }
    }

    /* BP large */
    @media screen and (min-width: 75em) { /* 1200px */
        &--lg {
            margin-bottom: 0;
        }
    }
}
