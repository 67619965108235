
#wrap {
    overflow-x: hidden;
    background-color: $color-white;

    @media screen and (min-width: $bp992) {
        padding-top: $header-height-desktop;
    }

    @media screen and (min-width: 125em) {/* 2000px */
        max-width: 1920px;
        margin: 0 auto;
        box-shadow: 0 2px 32px rgba(0,0,0,0.25);
    }
}

.home #wrap {
    @media screen and (min-width: $bp992) {
        padding-top: 0;
    }
}
