@charset "UTF-8";
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Basic system font stacks
*/
/* Poppins Weights: Regular: 400, Bold: 700 */
/* Lato Weights: Light: 300, Regular: 400 */
/*
Weights
*/
/*
Sizes
*/
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in down */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.highlight-pulse {
  animation: highlightPulse 700ms ease;
  animation-fill-mode: forwards;
}

.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
/* Red Warning Button */
/* Light Grey */
/* Mid Grey */
/* Green */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */
/* ---- Base HTML ---- */
@viewport {
  width: device-width;
  zoom: 1;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

html,
body {
  background: #ffffff;
  height: 100%;
}
@media screen and (min-width: 125em) {
  html,
body {
    /* 2000px */
    background: #6A6D73;
  }
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: 1.5rem;
  color: #6A6D73;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* ---- Unpadded row ---- */
.row-gap-0 {
  margin-right: 0;
  margin-left: 0;
}
.row-gap-0 > .col-xs,
.row-gap-0 > .col-xs-1,
.row-gap-0 > .col-xs-2,
.row-gap-0 > .col-xs-3,
.row-gap-0 > .col-xs-4,
.row-gap-0 > .col-xs-5,
.row-gap-0 > .col-xs-6,
.row-gap-0 > .col-xs-7,
.row-gap-0 > .col-xs-8,
.row-gap-0 > .col-xs-9,
.row-gap-0 > .col-xs-10,
.row-gap-0 > .col-xs-11,
.row-gap-0 > .col-xs-12,
.row-gap-0 > .col-xs-grow,
.row-gap-0 > .col-xs-shrink {
  padding-right: 0;
  padding-left: 0;
}

/* ---- Small padded row ---- */
@media screen and (min-width: 48em) {
  .row-gap-sm {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-sm {
    margin-right: -18px;
    margin-left: -18px;
  }
}
@media screen and (min-width: 48em) {
  .row-gap-sm > .col-xs,
.row-gap-sm > .col-xs-1,
.row-gap-sm > .col-xs-2,
.row-gap-sm > .col-xs-3,
.row-gap-sm > .col-xs-4,
.row-gap-sm > .col-xs-5,
.row-gap-sm > .col-xs-6,
.row-gap-sm > .col-xs-7,
.row-gap-sm > .col-xs-8,
.row-gap-sm > .col-xs-9,
.row-gap-sm > .col-xs-10,
.row-gap-sm > .col-xs-11,
.row-gap-sm > .col-xs-12,
.row-gap-sm > .col-xs-grow,
.row-gap-sm > .col-xs-shrink {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-sm > .col-xs,
.row-gap-sm > .col-xs-1,
.row-gap-sm > .col-xs-2,
.row-gap-sm > .col-xs-3,
.row-gap-sm > .col-xs-4,
.row-gap-sm > .col-xs-5,
.row-gap-sm > .col-xs-6,
.row-gap-sm > .col-xs-7,
.row-gap-sm > .col-xs-8,
.row-gap-sm > .col-xs-9,
.row-gap-sm > .col-xs-10,
.row-gap-sm > .col-xs-11,
.row-gap-sm > .col-xs-12,
.row-gap-sm > .col-xs-grow,
.row-gap-sm > .col-xs-shrink {
    padding-right: 18px;
    padding-left: 18px;
  }
}

/* ---- Medium padded row ---- */
.row-gap-md {
  margin-right: -1.83333333vw;
  margin-left: -1.83333333vw;
}
@media screen and (min-width: 48em) {
  .row-gap-md {
    margin-right: -1.91666667vw;
    margin-left: -1.91666667vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-md {
    margin-right: -30px;
    margin-left: -30px;
  }
}
.row-gap-md > .col-xs,
.row-gap-md > .col-xs-1,
.row-gap-md > .col-xs-2,
.row-gap-md > .col-xs-3,
.row-gap-md > .col-xs-4,
.row-gap-md > .col-xs-5,
.row-gap-md > .col-xs-6,
.row-gap-md > .col-xs-7,
.row-gap-md > .col-xs-8,
.row-gap-md > .col-xs-9,
.row-gap-md > .col-xs-10,
.row-gap-md > .col-xs-11,
.row-gap-md > .col-xs-12,
.row-gap-md > .col-xs-grow,
.row-gap-md > .col-xs-shrink {
  padding-right: 1.83333333vw;
  padding-left: 1.83333333vw;
}
@media screen and (min-width: 48em) {
  .row-gap-md > .col-xs,
.row-gap-md > .col-xs-1,
.row-gap-md > .col-xs-2,
.row-gap-md > .col-xs-3,
.row-gap-md > .col-xs-4,
.row-gap-md > .col-xs-5,
.row-gap-md > .col-xs-6,
.row-gap-md > .col-xs-7,
.row-gap-md > .col-xs-8,
.row-gap-md > .col-xs-9,
.row-gap-md > .col-xs-10,
.row-gap-md > .col-xs-11,
.row-gap-md > .col-xs-12,
.row-gap-md > .col-xs-grow,
.row-gap-md > .col-xs-shrink {
    padding-right: 1.91666667vw;
    padding-left: 1.91666667vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-md > .col-xs,
.row-gap-md > .col-xs-1,
.row-gap-md > .col-xs-2,
.row-gap-md > .col-xs-3,
.row-gap-md > .col-xs-4,
.row-gap-md > .col-xs-5,
.row-gap-md > .col-xs-6,
.row-gap-md > .col-xs-7,
.row-gap-md > .col-xs-8,
.row-gap-md > .col-xs-9,
.row-gap-md > .col-xs-10,
.row-gap-md > .col-xs-11,
.row-gap-md > .col-xs-12,
.row-gap-md > .col-xs-grow,
.row-gap-md > .col-xs-shrink {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/* ---- Large padded row ---- */
.row-gap-lg {
  margin-right: -2.4444444vw;
  margin-left: -2.4444444vw;
}
@media screen and (min-width: 48em) {
  .row-gap-lg {
    margin-right: -2.555555vw;
    margin-left: -2.555555vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-lg {
    margin-right: -40px;
    margin-left: -40px;
  }
}
.row-gap-lg > .col-xs,
.row-gap-lg > .col-xs-1,
.row-gap-lg > .col-xs-2,
.row-gap-lg > .col-xs-3,
.row-gap-lg > .col-xs-4,
.row-gap-lg > .col-xs-5,
.row-gap-lg > .col-xs-6,
.row-gap-lg > .col-xs-7,
.row-gap-lg > .col-xs-8,
.row-gap-lg > .col-xs-9,
.row-gap-lg > .col-xs-10,
.row-gap-lg > .col-xs-11,
.row-gap-lg > .col-xs-12,
.row-gap-lg > .col-xs-grow,
.row-gap-lg > .col-xs-shrink {
  padding-right: 2.444444vw;
  padding-left: 2.444444vw;
}
@media screen and (min-width: 48em) {
  .row-gap-lg > .col-xs,
.row-gap-lg > .col-xs-1,
.row-gap-lg > .col-xs-2,
.row-gap-lg > .col-xs-3,
.row-gap-lg > .col-xs-4,
.row-gap-lg > .col-xs-5,
.row-gap-lg > .col-xs-6,
.row-gap-lg > .col-xs-7,
.row-gap-lg > .col-xs-8,
.row-gap-lg > .col-xs-9,
.row-gap-lg > .col-xs-10,
.row-gap-lg > .col-xs-11,
.row-gap-lg > .col-xs-12,
.row-gap-lg > .col-xs-grow,
.row-gap-lg > .col-xs-shrink {
    padding-right: 2.555555vw;
    padding-left: 2.555555vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-lg > .col-xs,
.row-gap-lg > .col-xs-1,
.row-gap-lg > .col-xs-2,
.row-gap-lg > .col-xs-3,
.row-gap-lg > .col-xs-4,
.row-gap-lg > .col-xs-5,
.row-gap-lg > .col-xs-6,
.row-gap-lg > .col-xs-7,
.row-gap-lg > .col-xs-8,
.row-gap-lg > .col-xs-9,
.row-gap-lg > .col-xs-10,
.row-gap-lg > .col-xs-11,
.row-gap-lg > .col-xs-12,
.row-gap-lg > .col-xs-grow,
.row-gap-lg > .col-xs-shrink {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.row-gap-col {
  margin-right: -3.66666667vw;
  margin-left: -3.66666667vw;
}
@media screen and (min-width: 48em) {
  .row-gap-col {
    margin-right: -3.83333333vw;
    margin-left: -3.83333333vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-col {
    margin-right: -60px;
    margin-left: -60px;
  }
}
.row-gap-col > .col-xs,
.row-gap-col > .col-xs-1,
.row-gap-col > .col-xs-2,
.row-gap-col > .col-xs-3,
.row-gap-col > .col-xs-4,
.row-gap-col > .col-xs-5,
.row-gap-col > .col-xs-6,
.row-gap-col > .col-xs-7,
.row-gap-col > .col-xs-8,
.row-gap-col > .col-xs-9,
.row-gap-col > .col-xs-10,
.row-gap-col > .col-xs-11,
.row-gap-col > .col-xs-12,
.row-gap-col > .col-xs-grow,
.row-gap-col > .col-xs-shrink {
  padding-right: 3.66666667vw;
  padding-left: 3.66666667vw;
}
@media screen and (min-width: 48em) {
  .row-gap-col > .col-xs,
.row-gap-col > .col-xs-1,
.row-gap-col > .col-xs-2,
.row-gap-col > .col-xs-3,
.row-gap-col > .col-xs-4,
.row-gap-col > .col-xs-5,
.row-gap-col > .col-xs-6,
.row-gap-col > .col-xs-7,
.row-gap-col > .col-xs-8,
.row-gap-col > .col-xs-9,
.row-gap-col > .col-xs-10,
.row-gap-col > .col-xs-11,
.row-gap-col > .col-xs-12,
.row-gap-col > .col-xs-grow,
.row-gap-col > .col-xs-shrink {
    padding-right: 3.83333333vw;
    padding-left: 3.83333333vw;
  }
}
@media screen and (min-width: 100em) {
  .row-gap-col > .col-xs,
.row-gap-col > .col-xs-1,
.row-gap-col > .col-xs-2,
.row-gap-col > .col-xs-3,
.row-gap-col > .col-xs-4,
.row-gap-col > .col-xs-5,
.row-gap-col > .col-xs-6,
.row-gap-col > .col-xs-7,
.row-gap-col > .col-xs-8,
.row-gap-col > .col-xs-9,
.row-gap-col > .col-xs-10,
.row-gap-col > .col-xs-11,
.row-gap-col > .col-xs-12,
.row-gap-col > .col-xs-grow,
.row-gap-col > .col-xs-shrink {
    padding-right: 60px;
    padding-left: 60px;
  }
}

/* ---- Bottom margin for stacked columns ---- */
.col-stack-lh {
  margin-bottom: 1.6rem;
}
.col-stack-sm {
  margin-bottom: 24px;
}
.col-stack-md {
  margin-bottom: 40px;
}
.col-stack-lg {
  margin-bottom: 7.3333334vw;
}
@media screen and (min-width: 48em) {
  .col-stack-lg {
    /* 768px */
    margin-bottom: 7.3333334vw;
  }
}
@media screen and (min-width: 87.5em) {
  .col-stack-lg {
    /* 1400px */
    margin-bottom: 53.33333333px;
  }
}
@media screen and (min-width: 100em) {
  .col-stack-lg {
    /* 1600px */
    margin-bottom: 60px;
  }
}

.col-unstack {
  /* BP Smaller */
  /* BP small */
  /* BP medium */
  /* BP large */
}
@media screen and (min-width: 36em) {
  .col-unstack {
    /* 576px */
  }
  .col-unstack--xsm {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .col-unstack {
    /* 768px */
  }
  .col-unstack--sm {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .col-unstack {
    /* 992px */
  }
  .col-unstack--md {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .col-unstack {
    /* 1200px */
  }
  .col-unstack--lg {
    margin-bottom: 0;
  }
}

/* for minor lists made using flex that will stack at an flexible point */
.flex-stack-list {
  margin-bottom: -5px;
}
.flex-stack-list > * {
  margin-bottom: 5px;
}

.flex-stack-list-sm {
  margin-bottom: -12px;
}
.flex-stack-list-sm > * {
  margin-bottom: 12px;
}

.flex-stack-list-lg {
  margin-bottom: -16px;
}
.flex-stack-list-lg > * {
  margin-bottom: 16px;
}

@media screen and (min-width: 48em) {
  /* 768px */
  .flex-stack-list-none--sm {
    margin-bottom: 0;
  }
  .flex-stack-list-none--sm > * {
    margin-bottom: 0;
  }
}
/* BP large */
@media screen and (min-width: 75em) {
  /* 1200px */
  .flex-stack-list-none--lg {
    margin-bottom: 0;
  }
  .flex-stack-list-none--lg > * {
    margin-bottom: 0;
  }
}
/* ---- Row - nowrap  ---- */
.row-nowrap {
  flex-wrap: nowrap;
}
@media screen and (min-width: 22.5em) {
  .row-nowrap--xsm {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 48em) {
  .row-nowrap--sm {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 62em) {
  .row-nowrap--md {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 75em) {
  .row-nowrap--lg {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 87.5em) {
  .row-nowrap--lg2 {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 100em) {
  .row-nowrap--xlg {
    flex-wrap: nowrap;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.container {
  width: 88%;
  margin: 0 auto;
  /* BP small */
  /* BP xxlarge */
}
@media screen and (min-width: 48em) {
  .container {
    width: calc(100vw - 90px);
  }
}
@media screen and (min-width: 100em) {
  .container {
    max-width: 1506px;
  }
}

#wrap {
  overflow-x: hidden;
  background-color: #ffffff;
}
@media screen and (min-width: 62em) {
  #wrap {
    padding-top: 146px;
  }
}
@media screen and (min-width: 125em) {
  #wrap {
    /* 2000px */
    max-width: 1920px;
    margin: 0 auto;
    box-shadow: 0 2px 32px rgba(0, 0, 0, 0.25);
  }
}

@media screen and (min-width: 62em) {
  .home #wrap {
    padding-top: 0;
  }
}

.bg-white {
  background-color: #ffffff;
}

.bg-lightgrey {
  background-color: #E9EFF2;
}
.bg-lightgrey.container--bleed-left {
  position: relative;
}
.bg-lightgrey.container--bleed-left:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #E9EFF2;
}
.bg-lightgrey.container--bleed-right {
  position: relative;
}
.bg-lightgrey.container--bleed-right:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #E9EFF2;
}

.bg-primary {
  background-color: #1D1E26;
}

.bg-00 {
  background-color: #1B244A;
}

.bg-01 {
  background-color: #571E25;
}

.bg-02 {
  background-color: #734226;
}

.bg-03 {
  background-color: #BCAD98;
}

.section,
.section-regular {
  padding-top: 45px;
  padding-bottom: 45px;
}

.section-small {
  padding-top: 27px;
  padding-bottom: 27px;
}

.section-large {
  padding-top: 63px;
  padding-bottom: 63px;
}

.section-t-sm {
  padding-top: 27px;
}

.section-t0 {
  padding-top: 0;
}

.section-b0 {
  padding-bottom: 0;
}

/* BP small */
@media screen and (min-width: 48em) {
  .section,
.section-regular {
    padding-top: 63px;
    padding-bottom: 63px;
  }

  .section-small {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .section-large {
    padding-top: 108px;
    padding-bottom: 108px;
  }

  .section-t-sm {
    padding-top: 36px;
  }

  .section-t0 {
    padding-top: 0;
  }

  .section-b0 {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 35em) {
  .section-header {
    text-align: center;
  }
}
.section-header__heading {
  display: block;
  line-height: normal;
}
.section-header__subtitle {
  position: absolute;
  left: -15px;
  top: 0;
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
}
@media screen and (min-width: 62em) {
  .section-header__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.section-header__subtitle.subtitle--00 {
  background-color: #1B244A;
}
.section-header__subtitle.subtitle--01 {
  background-color: #571E25;
}
.section-header__subtitle.subtitle--02 {
  background-color: #734226;
}
.section-header__subtitle.subtitle--03 {
  background-color: #BCAD98;
}
@media screen and (min-width: 62em) {
  .section-header__subtitle {
    left: -21px;
  }
}
.section-header__title {
  display: block;
  position: relative;
  padding-top: 36px;
  color: #1D1E26;
  font-size: 3.6rem;
}
@media screen and (min-width: 35em) {
  .section-header__title {
    font-size: 6rem;
  }
}
@media screen and (min-width: 62em) {
  .section-header__title {
    font-size: 9rem;
  }
}
@media screen and (min-width: 35em) {
  .section-header__title {
    display: inline-block;
  }
}
@media screen and (min-width: 62em) {
  .section-header__title {
    padding-top: 42px;
  }
}
.section-header--spacer {
  margin-bottom: 2.7rem;
}
@media screen and (min-width: 35em) {
  .section-header--spacer {
    margin-bottom: 3.6rem;
  }
}
@media screen and (min-width: 62em) {
  .section-header--spacer {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 87.5em) {
  .section-header--spacer {
    margin-bottom: 7.2rem;
  }
}
.section-header__introduction {
  text-align: center;
  font-size: 1.8rem;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2.7rem;
}
@media screen and (min-width: 35em) {
  .section-header__introduction {
    margin-top: 3.6rem;
  }
}
@media screen and (min-width: 75em) {
  .section-header__introduction {
    font-size: 2.1rem;
  }
}
.section-header__title-append {
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  margin-top: 0.4em;
  line-height: normal;
}
@media screen and (min-width: 35em) {
  .section-header__title-append {
    font-size: 40%;
  }
}
@media screen and (min-width: 62em) {
  .section-header__title-append {
    font-size: 3.6rem;
  }
}

.section-subheader {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 35em) {
  .section-subheader {
    margin-bottom: 2.7rem;
  }
}
@media screen and (min-width: 48em) {
  .section-subheader {
    margin-bottom: 3.6rem;
  }
}
@media screen and (min-width: 35em) {
  .section-subheader {
    text-align: center;
  }
}
.section-subheader__heading {
  display: block;
  line-height: normal;
  margin-bottom: 0.2em;
}
.section-subheader__subtitle {
  position: absolute;
  left: -15px;
  top: 0;
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
}
@media screen and (min-width: 62em) {
  .section-subheader__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.section-subheader__subtitle.subtitle--00 {
  background-color: #1B244A;
}
.section-subheader__subtitle.subtitle--01 {
  background-color: #571E25;
}
.section-subheader__subtitle.subtitle--02 {
  background-color: #734226;
}
.section-subheader__subtitle.subtitle--03 {
  background-color: #BCAD98;
}
@media screen and (min-width: 62em) {
  .section-subheader__subtitle {
    left: -21px;
  }
}
.section-subheader__title {
  display: block;
  position: relative;
  padding-top: 36px;
  color: #1D1E26;
  font-size: 3rem;
}
@media screen and (min-width: 35em) {
  .section-subheader__title {
    font-size: 3.6rem;
    display: inline-block;
  }
}
@media screen and (min-width: 48em) {
  .section-subheader__title {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 62em) {
  .section-subheader__title {
    font-size: 6rem;
    padding-top: 42px;
  }
}
.section-subheader__title.sans-subtitle {
  padding-top: 0;
}

.heading-multipart {
  margin-bottom: 36px;
}
@media screen and (min-width: 35em) {
  .heading-multipart {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
  }
}
.heading-multipart__heading {
  line-height: 1;
  display: inline-block;
}
.heading-multipart span {
  display: block;
  margin-bottom: 0.2em;
}
.heading-multipart__pretitle {
  font-size: 1.8rem;
  margin-left: -15px;
}
@media screen and (min-width: 35em) {
  .heading-multipart__pretitle {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 62em) {
  .heading-multipart__pretitle {
    margin-right: -21px;
  }
}
.heading-multipart__title {
  display: block;
  position: relative;
  color: #1D1E26;
  font-size: 3rem;
}
@media screen and (min-width: 35em) {
  .heading-multipart__title {
    font-size: 3.6rem;
    display: inline-block;
  }
}
@media screen and (min-width: 48em) {
  .heading-multipart__title {
    font-size: 4.5rem;
  }
}
@media screen and (min-width: 62em) {
  .heading-multipart__title {
    font-size: 6rem;
  }
}
.heading-multipart__posttitle {
  font-size: 2.1rem;
  text-align: right;
  margin-right: -15px;
}
@media screen and (min-width: 35em) {
  .heading-multipart__posttitle {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 62em) {
  .heading-multipart__posttitle {
    margin-right: -21px;
  }
}

.section-header.hide-title .section-header__heading {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.section-header.hide-title .section-header__heading.focusable:active, .section-header.hide-title .section-header__heading.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.section-header.hide-title .breadcrumb {
  margin-top: 0;
}

.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb {
  margin-bottom: 20px;
}

.block-large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb-large {
  margin-bottom: 20px;
}

@media screen and (min-width: 22.5em) {
  .block-large {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .block-mb-large {
    margin-bottom: 25px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .block-large {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-mb-large {
    margin-bottom: 30px;
  }
}
.box {
  padding: 15px;
}
.box > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box {
    padding: 21px;
  }
}
@media screen and (min-width: 48em) {
  .box {
    padding: 36px;
  }
}

.box--tiny {
  padding: 15px;
}
.box--tiny > *:last-child {
  margin-bottom: 0;
}

.box--small {
  padding: 15px;
}
.box--small > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box--small {
    padding: 21px;
  }
}

.box--large {
  padding: 15px;
}
.box--large > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box--large {
    padding: 21px;
  }
}
@media screen and (min-width: 48em) {
  .box--large {
    padding: 45px;
  }
}

.box-lined {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #1B244A;
}

.box-lined--grey {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #6A6D73;
}

.box-lined--00 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #1B244A;
}

.box-lined--01 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #571E25;
}

.box-lined--02 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #734226;
}

.box-lined--03 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #BCAD98;
}

.box-lined--warning {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #CD4C1D;
}

.box-lined--error {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #C32C2A;
}

.box-lined--positive {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #60962F;
}

.box-lined-bottom {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #1B244A;
}
.box-lined-bottom.box-lined--grey {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #6A6D73;
}
.box-lined-bottom.box-lined--00 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #1B244A;
}
.box-lined-bottom.box-lined--01 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #571E25;
}
.box-lined-bottom.box-lined--02 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #734226;
}
.box-lined-bottom.box-lined--03 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #BCAD98;
}

.box-lined-top {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #1B244A;
}
.box-lined-top.box-lined--grey {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #6A6D73;
}
.box-lined-top.box-lined--00 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #1B244A;
}
.box-lined-top.box-lined--01 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #571E25;
}
.box-lined-top.box-lined--02 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #734226;
}
.box-lined-top.box-lined--03 {
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-top: 3px solid #BCAD98;
}

.box.box--x0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

/* BP small */
@media screen and (min-width: 35em) {
  .text-align-right--xsm {
    text-align: right;
  }

  .text-align-left--xsm {
    text-align: left;
  }

  .text-align-center--xsm {
    text-align: center;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .text-align-right--sm {
    text-align: right;
  }

  .text-align-left--sm {
    text-align: left;
  }

  .text-align-center--sm {
    text-align: center;
  }
}
/* BP medium */
@media screen and (min-width: 62em) {
  .text-align-right--md {
    text-align: right;
  }

  .text-align-left--md {
    text-align: left;
  }

  .text-align-center--md {
    text-align: center;
  }
}
/* BP large */
@media screen and (min-width: 75em) {
  .text-align-right--lg {
    text-align: right;
  }

  .text-align-left--lg {
    text-align: left;
  }

  .text-align-center--lg {
    text-align: center;
  }
}
.left,
.center,
.right {
  margin: 30px auto;
  display: block;
  clear: both;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  .left {
    margin: 0 30px 30px 0;
    float: left;
  }

  .right {
    margin: 0 0 30px 30px;
    float: right;
  }
}
/* ---- Text ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1.5rem 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 1.1;
}
@media screen and (min-width: 35em) {
  h1, .h1 {
    font-size: 6rem;
  }
}
@media screen and (min-width: 48em) {
  h1, .h1 {
    font-size: 9rem;
  }
}

h2, .h2 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 300;
  font-size: 2.7rem;
  line-height: 1.5;
}
@media screen and (min-width: 35em) {
  h2, .h2 {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 48em) {
  h2, .h2 {
    font-size: 6rem;
  }
}

h3, .h3 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
}
@media screen and (min-width: 35em) {
  h3, .h3 {
    font-size: 2.1rem;
  }
}

h4, .h4 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  font-size: 1.65rem;
}
@media screen and (min-width: 35em) {
  h4, .h4 {
    font-size: 1.8rem;
  }
}

h5, .h5 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}
@media screen and (min-width: 35em) {
  h5, .h5 {
    font-size: 1.8rem;
  }
}

h6, .h6 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 1.5rem;
}

small {
  font-size: 1.28rem;
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.title-style-01 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 3.6rem;
}
@media screen and (min-width: 35em) {
  .title-style-01 {
    font-size: 6rem;
  }
}
@media screen and (min-width: 62em) {
  .title-style-01 {
    font-size: 9rem;
  }
}

.title-style-02 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  line-height: normal;
  font-size: 1.8rem;
}
@media screen and (min-width: 35em) {
  .title-style-02 {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 62em) {
  .title-style-02 {
    font-size: 3.6rem;
  }
}

.title-style-03 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
}
@media screen and (min-width: 62em) {
  .title-style-03 {
    font-size: 2.1rem;
  }
}

.title-style-04 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
}
@media screen and (min-width: 75em) {
  .title-style-04 {
    font-size: 2.1rem;
  }
}

.title-style-paragraph {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.subtitle {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
@media screen and (min-width: 62em) {
  .subtitle {
    font-size: 1.5rem;
  }
}

p,
address {
  margin: 0 0 1.5rem;
}

/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #FFC57D;
}

::selection {
  color: #000;
  background: #FFC57D;
}

/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #c6bfbf;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background: rgba(0, 0, 0, 0.07);
  color: #070709;
  text-decoration: none;
}

mark {
  display: inline-block;
  line-height: normal;
  background-color: rgba(255, 197, 125, 0.75);
  color: #070709;
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  border-radius: 2px;
  padding: 0.125em 0.25em;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  color: #484040;
  background: #efefef;
  background: rgba(0, 0, 0, 0.07);
  padding: 2px;
  margin: 0;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E9EFF2;
  margin: 2rem 0;
  padding: 0;
}

ul,
ol dl {
  margin: 0 0 1.6rem;
  padding: 0 0 0 2rem;
}

li ul,
li ol {
  margin: 1rem 0;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol ul {
  list-style-type: disc;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ul.inline,
ol.inline {
  list-style-type: none;
  margin-left: 0;
}

ul.inline > li,
ol.inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

/* ---- Buttons and links ---- */
a {
  color: #1B244A;
  word-break: break-word;
  transition: color 250ms ease-in-out;
}

a:hover,
a:active,
a:focus {
  color: black;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
  animation: highlightPulse 700ms ease;
  outline: 3px solid #FFC57D;
  outline-offset: 4px;
}

.active-target {
  animation: highlightPulseOutline 2100ms ease;
}

.button {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: #1B244A;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  padding: 12px 27px;
}
.button:hover, .button:focus, .button:active {
  background-color: #0d1225;
  color: #ffffff;
}
.button svg path {
  fill: #ffffff;
}
.button:after {
  content: " >";
}

.button--no-chevron:after {
  content: none;
}

/* Button on reverse */
.reverse-text .button {
  color: #fff;
  background-color: #BCAD98;
}
.reverse-text .button:hover, .reverse-text .button:focus, .reverse-text .button:active {
  background-color: #a89479;
  color: #ffffff;
  opacity: 1;
}

/* - Button icons - */
.button__icon {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
}

.button__icon--right {
  margin-left: 4px;
  margin-right: -2px;
}

.button__icon--left {
  margin-right: 4px;
  margin-left: -2px;
}

.button__icon svg {
  width: 16px;
  height: 16px;
}

.button__icon svg path {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
  fill: #1D1E26;
}

.button__icon--secondary svg path {
  fill: #1B244A;
}

.button:hover .button__icon--light-purple svg path {
  fill: #ffffff;
}

/* - Button styles - */
/* Alternative */
.button--alt {
  background-color: #1D1E26;
  border-color: #1D1E26;
}
.button--alt:hover, .button--alt:focus {
  background-color: #070709;
  border-color: #070709;
}

/* Secondary */
.button--secondary {
  background-color: #1B244A;
  border-color: #1B244A;
}
.button--secondary:hover, .button--secondary:focus {
  background-color: #0d1225;
  border-color: #0d1225;
}

/* Red Warning Button */
.button--warning {
  background-color: #9E0E0B;
  color: #ffffff;
}
.button--warning:hover, .button--warning:focus {
  background-color: #6e0a08;
  color: #ffffff;
}

/* Grey */
.button--neutral {
  background-color: #E9EFF2;
  color: #2b2b2b;
}
.button--neutral:hover, .button--neutral:focus {
  background-color: #b8b8b8;
  color: #2b2b2b;
}
.button--neutral svg path {
  fill: #2b2b2b;
}

/* Green */
.button--positive {
  background-color: #60962F;
  color: #ffffff;
}
.button--positive:hover, .button--positive:focus {
  background-color: #2D4A13;
  color: #ffffff;
}

.button--grey {
  background-color: #6A6D73;
  color: #ffffff;
}
.button--grey:hover, .button--grey:focus {
  background-color: #4A4B51;
  color: #ffffff;
}
.button--grey svg path {
  fill: #ffffff;
}

/* Block */
.button--block {
  display: block;
  width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
  border: none;
  opacity: 0.4;
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* Extra Small */
.button--xsmall,
.button.button--xsmall {
  padding: 6px 9px;
  font-size: 1.2rem;
}

/* Small */
.button-small,
.button--small {
  font-size: 1.5rem;
  line-height: 1;
  padding: 8px calc(12px - 0.25em) 8px 12px;
}
@media screen and (min-width: 48em) {
  .button-small,
.button--small {
    padding: 8px 12px 8px;
  }
}

/* BP medium */
@media screen and (min-width: 62em) {
  /* Large */
  .button-large,
.button--large {
    font-size: 2.4rem;
    padding: 16px 32px;
  }

  .button--large .button__icon {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon svg {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon--right {
    margin-left: 4px;
    margin-right: -8px;
  }

  .button--large .button__icon--left {
    margin-right: 4px;
    margin-left: -8px;
  }
}
.text-link {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
}
.text-link svg path {
  fill: #1B244A;
}
.text-link:hover, .text-link:focus, .text-link:active {
  color: #1D1E26;
}
.text-link:hover svg path, .text-link:focus svg path, .text-link:active svg path {
  fill: #1D1E26;
}
.text-link:after {
  content: " >";
}

/* ---- Images and Icons ---- */
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary svg path {
  fill: #1D1E26;
}

.icon--00 svg path {
  fill: #1B244A;
}

.icon--01 svg path {
  fill: #571E25;
}

.icon--02 svg path {
  fill: #734226;
}

.icon--white svg path {
  fill: #ffffff;
}

.icon--03 svg path {
  fill: #BCAD98;
}

/* ---- Video ---- */
.tinymce-media-iframe,
.widget-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  clear: both;
}

.tinymce-media-iframe iframe,
.widget-video iframe,
.widget-video object,
.widget-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tinymce-media-iframe {
  margin: 2rem 0;
}

iframe {
  border: 0;
}

/* ---- Tables ---- */
table {
  border-collapse: collapse;
  border: 1px solid #E9EFF2;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #E9EFF2;
}

th,
thead td {
  background: #F0F4F6;
}

.table-striped {
  border: 1px solid transparent;
}
.table-striped th,
.table-striped td {
  padding: 12px;
  border: 1px solid transparent;
}
.table-striped tr:nth-child(even) {
  background-color: #ffffff;
}
.table-striped tr:nth-child(odd) {
  background-color: #F0F4F6;
}

.table-naked {
  border-color: transparent;
}
.table-naked th,
.table-naked td {
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: #F0F4F6;
}
.table-naked tr:first-child th,
.table-naked tr:first-child td {
  border-top: 1px solid #F0F4F6;
}

.table-guts {
  border-color: transparent;
}
.table-guts th,
.table-guts td {
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: #F0F4F6;
}
.table-guts th,
.table-guts td {
  border-right-color: #F0F4F6;
}
.table-guts tr th:last-child,
.table-guts tr td:last-child {
  border-right-color: transparent;
}
.table-guts tr:last-child th,
.table-guts tr:last-child td {
  border-bottom-color: transparent;
}

.table-invisible {
  border-color: transparent;
}
.table-invisible th,
.table-invisible td {
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid transparent;
}

.no-thead th {
  text-align: left;
  background: transparent;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #E9EFF2;
  font-size: 1.7rem;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #E9EFF2;
  padding: 12px 15px;
  text-align: left;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

@media screen and (max-width: 35em) {
  .table--responsive {
    font-size: 1.4rem;
  }

  .table--responsive,
.table--responsive tbody,
.table--responsive tr {
    display: block;
    border: none;
  }

  .table--responsive thead,
.table--responsive th {
    display: none;
  }

  .table--responsive tr {
    display: table;
    width: 100%;
  }

  .table--responsive tr.table--responsive__first-row {
    display: none;
  }

  .table--responsive td {
    display: table-row;
    padding: 0;
  }

  .table--responsive td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    background-color: #F0F0F0;
    border-right: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title] {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
  }

  .table--responsive tbody tr {
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title]:before,
.table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
/* ---- Helpers ---- */
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

/* ---- Hidden ---- */
.-hidden {
  display: none !important;
  visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
  visibility: hidden;
}

/* ---- Visibility Hidden ---- */
.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */
/* ---- Text ---- */
blockquote {
  margin: 1.5rem 0;
  padding: 20px 25px 20px;
  background: #f6f6f6;
  font-style: italic;
  position: relative;
  clear: both;
}

blockquote *:first-child {
  margin-top: 0;
}

blockquote *:last-child {
  margin-bottom: 0;
}

blockquote:before,
blockquote:after {
  position: absolute;
  font-size: 13rem;
  display: block;
  height: 25px;
  width: 50px;
  color: #35ab75;
  font-style: italic;
  line-height: 10px;
  font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", Georgia, serif;
}

blockquote:before {
  content: "“";
  top: 38px;
  left: -8px;
}

blockquote:after {
  content: "”";
  bottom: -36px;
  right: 18px;
}

@media screen and (min-width: 62em) {
  .blockquote--left {
    float: left;
    width: 45%;
    margin-left: 0px;
    margin-right: 20px;
    clear: right;
  }

  .blockquote--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    margin-right: 0px;
    clear: left;
  }
}
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
  clear: both;
}

/* BP medium */
@media screen and (min-width: 62em) {
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
    clear: right;
  }

  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    clear: left;
  }
}
.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
  margin-bottom: 0;
}

.message-block {
  margin: 0 0 1.5rem;
  padding: 1em;
  padding-left: 1.5em;
  border: 1px solid #F0F4F6;
  border-left: 4px solid #1B244A;
  border-radius: 3px;
}
.message-block > *:last-child {
  margin-bottom: 0;
}
.message-block__title {
  color: #1B244A;
}
.message-block--small {
  font-size: 80%;
}
.message-block--small .message-block__title {
  font-size: 1.5rem;
}
.message-block strong {
  color: #1D1E26;
}

:root {
  --expando-trigger-font-size: 1.8rem;
}
@media screen and (min-width: 35em) {
  :root {
    --expando-trigger-font-size: 2.1rem;
  }
}

.js .expando {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* - Expanded - */
.js .expando[aria-expanded=true] {
  clip: auto;
  height: auto;
  margin: 0 0 1.5rem;
  overflow: visible;
  position: relative;
  width: auto;
  padding: calc(var(--expando-trigger-font-size)*0.5);
  padding-left: calc(var(--expando-trigger-font-size) + 26px);
  background-color: #F0F4F6;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}
.js .expando[aria-expanded=true] .expando-open__icon:before {
  transform: rotate(-45deg);
}
.js .expando[aria-expanded=true] .expando-open__icon:after {
  transform: rotate(45deg);
}

.js .expando p:last-of-type {
  margin-bottom: 0;
}

/* - Open button - */
.expando-trigger {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  font-size: var(--expando-trigger-font-size);
  padding: calc(var(--expando-trigger-font-size)*0.5);
  padding-left: calc(var(--expando-trigger-font-size) + 26px);
  background-color: #F0F4F6;
  color: #1B244A;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  line-height: inherit;
  transition: color 250ms ease-in-out;
}
.expando-trigger:hover, .expando-trigger:focus {
  color: #ffffff;
  background-color: #1B244A;
}
.expando-trigger:hover .expando-open__icon:before,
.expando-trigger:hover .expando-open__icon:after, .expando-trigger:focus .expando-open__icon:before,
.expando-trigger:focus .expando-open__icon:after {
  background-color: #ffffff;
}
.expando-trigger[aria-pressed=true] {
  color: #ffffff;
  background-color: #1B244A;
  margin-bottom: 0;
  outline: 1px dashed #E9EFF2;
}
.expando-trigger[aria-pressed=true]:hover, .expando-trigger[aria-pressed=true]:focus {
  background-color: #0d1225;
}
.expando-trigger[aria-pressed=true] .expando-open__icon:before,
.expando-trigger[aria-pressed=true] .expando-open__icon:after {
  background-color: #ffffff;
}
.expando-trigger[aria-pressed=true] .expando-open__icon:before {
  transform: rotate(-45deg);
}
.expando-trigger[aria-pressed=true] .expando-open__icon:after {
  transform: rotate(45deg);
}

.expando-open__icon {
  position: absolute;
  top: 50%;
  left: 0.75em;
  background-color: transparent;
  width: 26px;
  height: 26px;
  transform: translate(0, -50%);
}
.expando-open__icon:before, .expando-open__icon:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #1B244A;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 250ms ease-in-out, background-color 250ms ease-in-out;
}
.expando-open__icon:after {
  transform: rotate(90deg);
}

/* - Close button - */
.expando__close {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  text-indent: -9999px;
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  /* Hover */
}
.expando__close:before, .expando__close:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #571E25;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 200ms ease;
}
.expando__close:before {
  transform: rotate(-45deg);
}
.expando__close:after {
  transform: rotate(45deg);
}
.expando__close:hover:before, .expando__close:hover:after {
  background-color: #0D0800;
}

.site-messages {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-messages__item {
  display: block;
  margin: 0 0 15px;
  padding: 10px 20px;
  vertical-align: bottom;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.2em;
}
.site-messages__item:last-child {
  margin-bottom: 0;
}
.site-messages__item.site-messages__item--mb {
  margin-bottom: 1.5rem;
}

.site-messages .error,
.site-messages__item.error {
  background: #9E0E0B;
  border-left: 4px solid #C32C2A;
  color: #FFF !important;
}

.site-messages .confirm,
.site-messages__item.confirm {
  color: #FFF !important;
  background: #2D4A13;
  border-left: 4px solid #60962F;
}

.site-messages .warning,
.site-messages__item.warning {
  color: #FFF !important;
  background: #A72D00;
  border-left: 4px solid #CD4C1D;
}

.site-messages .attention,
.site-messages__item.attention {
  color: #FFF !important;
  background: #222d5e;
  border-left: 4px solid #364896;
}

.site-messages a {
  color: #FFF;
}

.site-messages--light .site-messages__item {
  background-color: transparent;
  color: #6A6D73 !important;
  padding-top: 0;
  padding-bottom: 0;
}
.site-messages--light .site-messages__item .site-messages__item__heading {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  display: block;
}
@media screen and (min-width: 62em) {
  .site-messages--light .site-messages__item .site-messages__item__heading {
    font-size: 1.5rem;
  }
}
.site-messages--light .site-messages__item.error {
  border-left-color: #C32C2A;
}
.site-messages--light .site-messages__item.error .site-messages__item__heading {
  color: #C32C2A;
}
.site-messages--light .site-messages__item.confirm {
  border-left-color: #60962F;
}
.site-messages--light .site-messages__item.confirm .site-messages__item__heading {
  color: #60962F;
}
.site-messages--light .site-messages__item.warning {
  border-left-color: #CD4C1D;
}
.site-messages--light .site-messages__item.warning .site-messages__item__heading {
  color: #CD4C1D;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
}

.tooltip__title {
  display: inline-block;
  position: relative;
  z-index: 1;
  opacity: 1;
  font: inherit;
  transition: opacity 100ms ease-in-out;
}
.tooltip__title a {
  color: inherit;
}
.tooltip__title a:hover, .tooltip__title a:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.tooltip__content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9fbfb;
  border: 1px solid #E9EFF2;
  padding: 0;
  font-size: 1.5rem;
  border-radius: 4px;
  margin-top: 7px;
  text-align: left;
  color: #6A6D73;
  z-index: 100;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
  font-size: 1.5rem;
  display: none;
  max-width: 270px;
  min-width: 120px;
}
@media screen and (min-width: 48em) {
  .tooltip__content {
    min-width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .tooltip__content {
    max-width: 400px;
    min-width: 280px;
  }
}
.tooltip__content p {
  font-size: 75%;
  margin-bottom: 0.25em;
}
.tooltip__content > *:last-child {
  margin-bottom: 0;
}
.tooltip__content small {
  font-size: 1.1rem;
}
.tooltip__content:before, .tooltip__content:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #f9fbfb transparent;
  z-index: 1;
}
.tooltip__content:after {
  left: 13px;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #E9EFF2 transparent;
  z-index: 0;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before,
.tooltip--center .tooltip__content:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  animation: fadeIn 200ms ease;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #9c9c9c;
  color: #6A6D73;
  width: 1em;
  height: 1em;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0.2em;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  display: block;
  width: 0.66em;
  height: 0.66em;
}
.tooltip__icon svg path {
  fill: #ffffff;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

.tooltip__header {
  background-color: #f9fbfb;
  border-bottom: 1px solid #E9EFF2;
  padding: 10px;
}
.tooltip__header .tooltip__header__title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  color: #0D0800;
  font-size: 1.5rem;
}

.tooltip__body {
  padding: 10px;
}

.tooltip__footer {
  padding: 10px;
}

.tooltip__body + .tooltip__footer {
  border-top: 1px solid #E9EFF2;
}

figure {
  margin: 0;
}
figure iframe {
  width: 100%;
}

.richtext figure {
  display: inline-block;
  background: none;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.richtext figure.image-full {
  display: block;
  text-align: center;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background: none;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .richtext figure.image-right {
    margin-left: 3rem;
    float: right;
  }
}
@media screen and (min-width: 48em) {
  .richtext figure.image-left {
    margin-right: 3rem;
    float: left;
  }
}
.richtext figure.image-center {
  display: block;
  text-align: center;
}
.richtext figure.image-center img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.richtext figure.widget-video {
  display: block;
  padding-bottom: 56.25%;
  margin: 0 0 1em;
}

figure.content-image {
  display: inline-block;
  background: none;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
figure.content-image.image-full {
  display: block;
  text-align: center;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background: none;
  padding: 0;
}
@media screen and (min-width: 48em) {
  figure.content-image.image-right {
    margin-left: 3rem;
    float: right;
  }
}
@media screen and (min-width: 48em) {
  figure.content-image.image-left {
    margin-right: 3rem;
    float: left;
  }
}
figure.content-image.image-center {
  display: block;
  text-align: center;
}
figure.content-image.image-center img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

figcaption {
  color: #7f7f7f;
  font-size: 1.2rem;
  margin-top: 0.2em;
  display: block;
}

.richtext iframe {
  width: 100%;
}

/* ---- Lists ---- */
.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  transition: left 200ms ease-in-out, transform 200ms ease-in-out, color 200ms ease-in-out;
  transform: rotate(0deg);
  transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  transition: background-color 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0px;
  margin-left: 4px;
  margin-right: 4px;
  height: 24px;
}

.social-list__item {
  display: inline-block;
  padding: 0 4px;
}

.social-list__item a {
  color: inherit;
  display: block;
}

.share-list {
  margin-top: 80px;
  margin-bottom: -32px;
  border-top: 1px solid #E9EFF2;
  padding-top: 16px;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}
.share-list__item {
  display: inline-block;
}
.share-list__item a {
  color: #7f7f7f;
  text-decoration: none;
  display: block;
  display: flex;
  align-items: center;
}
.share-list__item a:hover, .share-list__item a:focus, .share-list__item a:active {
  color: #2b2b2b;
}
.share-list__item a:hover svg path, .share-list__item a:focus svg path, .share-list__item a:active svg path {
  fill: #2b2b2b;
}
.share-list__item a svg {
  vertical-align: middle;
  margin-left: 4px;
}
.share-list__item a svg path {
  transition: fill 250ms ease-in-out;
  fill: #9c9c9c;
}

.widget-FileList {
  background: #E9EFF2;
  padding: 20px;
  margin-bottom: 16px;
}

.widget-FileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-FileList li {
  margin-bottom: 8px;
}

.widget-FileList li:last-child {
  margin-bottom: 0;
}

.widget-FileList .document {
  min-height: 24px;
  padding-left: 32px;
  background: url(../../assets/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
  background-image: url(../../assets/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
  background-image: url(../../assets/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
  background-image: url(../../assets/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
  background-image: url(../../assets/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
  background-image: url(../../assets/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
  background-image: url(../../assets/icon-system/icon_video.svg);
}

.widget-FileList a {
  display: inline-block;
  text-decoration: none;
  color: #1D1E26;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(29, 30, 38, 0);
  transition: border-color 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
  text-decoration: none;
  border-color: #1d1e26;
}

@media screen and (min-width: 22.5em) {
  .widget-FileList {
    padding: 30px;
  }
}
.sitemap {
  text-align: left;
}
@media screen and (min-width: 48em) {
  .sitemap {
    columns: 2;
  }
}
@media screen and (min-width: 62em) {
  .sitemap {
    columns: 3;
  }
}
.sitemap__section {
  break-inside: avoid;
}
.sitemap__section:first-child .sitemap__list-title {
  margin-top: 0;
}
.sitemap__list-title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
@media screen and (min-width: 62em) {
  .sitemap__list-title {
    font-size: 2.1rem;
  }
}
.sitemap ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sitemap ul > li:first-child > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.sitemap ul > li:last-child > a {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.sitemap .sitemap-item button {
  display: block;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-align: left;
  padding-left: 1.25em;
}
.sitemap .sitemap-item a,
.sitemap .sitemap-item button {
  text-decoration: none;
  display: block;
  line-height: 2;
  background-color: transparent;
  color: #6A6D73;
  border: 1px solid #F0F4F6;
  border-left: 3px solid #9c9c9c;
  margin-bottom: -1px;
  transition: color 250ms ease-in-out, border-color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.sitemap .sitemap-item a:hover, .sitemap .sitemap-item a:focus, .sitemap .sitemap-item a:active,
.sitemap .sitemap-item button:hover,
.sitemap .sitemap-item button:focus,
.sitemap .sitemap-item button:active {
  background-color: #F0F4F6;
  color: #1B244A;
  border-left: 3px solid #1B244A;
}
.sitemap .sitemap-item-depth1 a {
  padding-left: 1.25em;
}
.sitemap .sitemap-item-depth2 a {
  padding-left: 2.5em;
}
.sitemap .sitemap-item-depth3 a {
  padding-left: 3.75em;
}

.block-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.block-list li {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.block-list li:first-child {
  border-top: none;
}

.block-list a {
  display: block;
  text-decoration: none;
  padding: 7px 0;
}

/* ---- Navigation ---- */
.breadcrumb {
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: #9c9c9c;
}
@media screen and (min-width: 35em) {
  .breadcrumb {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
@media screen and (min-width: 62em) {
  .breadcrumb {
    font-size: 1.5rem;
  }
}
.breadcrumb > li {
  flex: 0 0 auto;
  display: block;
  line-height: normal;
  border-left: 1px solid #9c9c9c;
}
.breadcrumb > li:first-child {
  border-left: none;
}
.breadcrumb > li span {
  display: inline-block;
  padding: 2px 12px;
}
.breadcrumb a {
  position: relative;
  text-decoration: none;
  color: #1B244A;
  z-index: 1;
  display: block;
  padding: 2px 12px;
  line-height: normal;
}
.breadcrumb a:before {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: left 250ms ease, right 250ms ease, background-color 250ms ease;
  z-index: -1;
}
.breadcrumb a:hover, .breadcrumb a:focus {
  color: #ffffff;
}
.breadcrumb a:hover:before, .breadcrumb a:focus:before {
  background-color: #9c9c9c;
  left: 0;
  right: 0;
}

@media screen and (max-width: 34.9375em) {
  .breadcrumb {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4;
  }

  .breadcrumb > li:first-child,
.breadcrumb > li:last-child {
    display: none;
  }
}
.search-paginate {
  text-align: center;
  padding: 1.5rem;
  background: #b8b8b8;
}

.search-paginate .page {
  display: inline-block;
  background: #BCAD98;
  color: #fff;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  text-align: center;
  margin: 0 0.25rem;
}

.search-paginate .page.on {
  background: #907b5e;
}

.search-paginate .page-next {
  margin-left: 1.5rem;
}

.search-paginate .page-prev {
  margin-right: 1.5rem;
}

@supports (display: flex) {
  .search-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.navigation-megamenu {
  position: relative;
}

/** Desktop and Mobile styling
===================================*/
#primary-menu,
.megamenu,
.topnav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  transition: margin 250ms ease-in-out;
}

.menu-item__link {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
}

.megamenu .menu-item__link {
  white-space: normal;
  cursor: pointer;
  border: none;
  line-height: 1.5;
  background-color: transparent;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.megamenu__panel[aria-expanded=false] {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
}
.megamenu__panel[aria-expanded=true] {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  width: auto;
}

.megamenu__submenu {
  list-style-type: none;
  padding: 0;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 62em) {
  #primary-menu {
    z-index: 1000;
  }

  .megamenu,
.topnav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  /* ---- all level menu items ---- */
  .menu-item {
    position: relative;
  }

  .navigation-megamenu .menu-item {
    position: static;
  }

  /* ---- First level menu ---- */
  .menu-item-depth1 {
    text-align: center;
    flex: 0 1 auto;
  }

  .menu-item-depth1 > .menu-item__link {
    display: block;
    font-weight: 400;
    position: relative;
    z-index: 0;
    transition: color 250ms ease;
    flex: 0 0 auto;
  }

  .megamenu {
    margin-right: -21px;
  }
  .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
    padding: 12px 1px;
  }
  .megamenu .menu-item-depth1 {
    /* match the item hover state to the link hover state */
    /* Current item */
    /* ancestor item */
  }

  /* ---- Megamenu ---- */
  /* Remove relative positioning for mega menu */

  /* Megamenu columns */

  /* Megamenu submenus */
}
@media screen and (min-width: 62em) and (min-width: 75em) {
  .megamenu .menu-item-depth1 {
    margin-left: 15px;
  }
}
@media screen and (min-width: 62em) and (min-width: 100em) {
  .megamenu .menu-item-depth1 {
    margin-left: 45px;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1 > .menu-item__link {
    font-size: 1.8rem;
    padding: 12px 10px;
    color: #1D1E26;
  }
}
@media screen and (min-width: 62em) and (min-width: 87.5em) {
  .megamenu .menu-item-depth1 > .menu-item__link {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1 > .menu-item__link:before {
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    top: 0;
    bottom: 0;
    background-color: #1B244A;
    transition: left 250ms ease, right 250ms ease;
    z-index: -1;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1 > .menu-item__link:hover, .megamenu .menu-item-depth1 > .menu-item__link:focus {
    color: #ffffff;
  }
  .megamenu .menu-item-depth1 > .menu-item__link:hover:before, .megamenu .menu-item-depth1 > .menu-item__link:focus:before {
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1 > .menu-item__link--sale {
    color: #A11219;
  }
  .megamenu .menu-item-depth1 > .menu-item__link--sale:before {
    background-color: #A11219;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1 > .menu-item__link--client {
    color: #a33845;
    max-width: 11ch;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1:hover > .menu-item__link, .megamenu .menu-item-depth1:focus > .menu-item__link {
    color: #ffffff;
  }
  .megamenu .menu-item-depth1:hover > .menu-item__link:before, .megamenu .menu-item-depth1:focus > .menu-item__link:before {
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1.menu-current-item > .menu-item__link {
    color: red;
  }
}
@media screen and (min-width: 62em) {
  .megamenu .menu-item-depth1.menu-current-item-ancestor > .menu-item__link {
    color: red;
  }
}
@media screen and (min-width: 62em) {
  .topnav {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .topnav .menu-item-depth1 {
    transition: margin 200ms ease;
    margin-left: 9px;
    margin-right: 9px;
  }
}
@media screen and (min-width: 62em) and (min-width: 87.5em) {
  .topnav .menu-item-depth1 {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 62em) and (min-width: 100em) {
  .topnav .menu-item-depth1 {
    margin-left: 18px;
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1:first-child {
    margin-left: 0;
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) and (min-width: 87.5em) {
  .topnav .menu-item-depth1:first-child {
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) and (min-width: 100em) {
  .topnav .menu-item-depth1:first-child {
    margin-right: 45px;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1 .menu-item__link--logout {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: inherit;
    background: transparent;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    font: inherit;
    line-height: inherit;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
    padding: 0;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1 > .menu-item__link {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: rgba(74, 75, 81, 0.75);
    letter-spacing: 0.15em;
    /* Current item */
    /* ancestor item */
  }
  .topnav .menu-item-depth1 > .menu-item__link:before {
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    height: 1px;
    background-color: #4A4B51;
    transition: left 250ms ease, right 250ms ease;
    z-index: -1;
  }
  .topnav .menu-item-depth1 > .menu-item__link:hover, .topnav .menu-item-depth1 > .menu-item__link:focus {
    color: #4a4b51;
  }
  .topnav .menu-item-depth1 > .menu-item__link:hover:before, .topnav .menu-item-depth1 > .menu-item__link:focus:before {
    left: 0;
    right: 0.25em;
  }
  .topnav .menu-item-depth1 > .menu-item__link.menu-current-item > .menu-item__link {
    color: #000;
  }
  .topnav .menu-item-depth1 > .menu-item__link.menu-current-item-ancestor > .menu-item__link {
    color: red;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1.menu-item--telephone {
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) and (min-width: 75em) {
  .topnav .menu-item-depth1.menu-item--telephone {
    margin-right: 27px;
  }
}
@media screen and (min-width: 62em) and (min-width: 100em) {
  .topnav .menu-item-depth1.menu-item--telephone {
    margin-right: 54px;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link {
    letter-spacing: 0;
    display: flex;
    align-items: center;
  }
  .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link:before {
    content: none;
  }
  .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link .menu-item__link__label {
    display: block;
    position: relative;
    padding-left: 5px;
  }
  .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link .menu-item__link__label:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    height: 1px;
    background-color: #4A4B51;
    transition: left 250ms ease, right 250ms ease;
    z-index: -1;
  }
  .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link:hover .menu-item__link__label:before, .topnav .menu-item-depth1.menu-item--telephone > .menu-item__link:focus .menu-item__link__label:before {
    left: 5px;
    right: 0;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1.menu-item--shop {
    margin-left: 18px;
    margin-right: 18px;
  }
}
@media screen and (min-width: 62em) and (min-width: 75em) {
  .topnav .menu-item-depth1.menu-item--shop {
    margin-left: 27px;
    margin-right: 27px;
  }
}
@media screen and (min-width: 62em) and (min-width: 100em) {
  .topnav .menu-item-depth1.menu-item--shop {
    margin-left: 54px;
    margin-right: 54px;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1.menu-item--socials {
    margin-left: 0;
  }
}
@media screen and (min-width: 62em) {
  .topnav .menu-item-depth1 .social-list__item {
    padding: 0;
  }
  .topnav .menu-item-depth1 .social-list__item.facebook {
    margin-right: 18px;
  }
  .topnav .menu-item-depth1 .social-list__item.facebook a {
    padding-left: 9px;
    padding-right: 9px;
  }
}
@media screen and (min-width: 62em) {
  .megamenumenu-mega .menu-item {
    position: static;
  }
}
@media screen and (min-width: 62em) {
  .menu-item.megamenu-hover .megamenu,
.menu-item.megamenu-hold #primary-menu {
    padding: 40px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }
}
@media screen and (min-width: 62em) {
  .menu-item.megamenu-hold #primary-menu {
    display: block !important;
    height: auto !important;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel {
    left: 0;
    right: 0;
    background-color: #F0F4F6;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 2px solid #E9EFF2;
    z-index: 51;
    display: flex;
  }
  .megamenu__panel[aria-expanded=false] {
    visibility: hidden;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__image {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__content {
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__content__description {
    background-color: #d9e3e9;
    padding: 12px 16px;
    margin-left: -16px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__header {
    display: none;
  }
  .megamenu__panel__header__subtitle {
    margin-bottom: 0;
    line-height: 1.2;
    display: block;
    color: #ffffff;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 400;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    letter-spacing: 0.25em;
    font-size: 1.2rem;
    padding: 9px 15px;
    background-color: #571E25;
    font-size: 1.2rem;
    padding: 6px 9px;
    display: inline-block;
    margin-left: -9px;
  }
}
@media screen and (min-width: 62em) and (min-width: 62em) {
  .megamenu__panel__header__subtitle {
    font-size: 1.5rem;
    padding: 12px 21px;
    font-size: 1.2rem;
    padding: 6px 9px;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__header__title {
    margin-top: 0;
    margin-bottom: 1.2rem;
    line-height: 1;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    color: #1B244A;
    font-weight: 300;
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 62em) and (min-width: 62em) {
  .megamenu__panel__header__title {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 62em) and (min-width: 35em) {
  .megamenu__panel__header__title {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 62em) and (min-width: 48em) {
  .megamenu__panel__header__title {
    font-size: 6rem;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__panel__header__description {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  @supports (display: grid) {
    #primary-menu .megamenu__panel__content__columns {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
  }
}
@media screen and (min-width: 62em) {
  .megamenu__column {
    width: 31.33333333%;
    float: left;
    margin-right: 3%;
  }
  @supports (display: grid) {
    .megamenu__column {
      width: auto;
      float: none;
      margin-right: none;
    }
  }
}
@media screen and (min-width: 62em) {
  .megamenu__column:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__column__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__submenu .menu-item__link {
    padding: 5px 0;
    text-decoration: none;
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link--image {
    display: flex;
  }
  @supports (gap: 10px) {
    .menu-item__link--image {
      gap: 10px;
    }
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link__img {
    flex: 0 1 auto;
    margin-right: 10px;
    object-fit: contain;
    vertical-align: top;
  }
  @supports (gap: 10px) {
    .menu-item__link__img {
      margin-right: 0;
    }
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link__copy {
    flex: 1 1 auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .megamenu__submenu .menu-item__link:hover .menu-item__link__title,
.megamenu__submenu .menu-item__link:focus .menu-item__link__title,
.megamenu__submenu .menu-item__link:active .menu-item__link__title {
    text-decoration: underline;
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link__title {
    display: block;
    font-size: 1.65rem;
    letter-spacing: 0.1em;
    font-weight: 400;
  }
}
@media screen and (min-width: 62em) and (min-width: 87.5em) {
  .menu-item__link__title {
    letter-spacing: 0.25em;
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link__title:after {
    content: " >";
  }
}
@media screen and (min-width: 62em) {
  .menu-item__link__description {
    display: block;
    letter-spacing: normal;
    font-size: 1.5rem;
    color: #6A6D73;
  }
}
@media screen and (min-width: 75em) {
  .topnav .menu-item-depth1 > .menu-item__link {
    letter-spacing: 0.25em;
    font-size: 1.5rem;
  }

  .megamenu .menu-item-depth1 > .menu-item__link {
    font-size: 2.1rem;
  }

  .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
    padding: 12px 12px;
    font-size: 1.95rem;
  }

  .megamenu .menu-item-depth1 > .menu-item__link--client {
    max-width: calc(14ch + 24px);
  }

  .megamenu__panel__image {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 25%;
    max-width: 25%;
    object-fit: cover;
  }

  .megamenu__panel__content {
    padding-right: 4vw;
    padding-left: 45px;
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 87.5em) {
  .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
    padding: 12px 21px;
  }

  .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
    font-size: 2.1rem;
  }

  .megamenu .menu-item-depth1 > .menu-item__link--client {
    max-width: calc(16ch + 42px);
  }
}
@media screen and (min-width: 100em) {
  .megamenu .menu-item-depth1 > .menu-item__link--client {
    max-width: calc(19ch + 42px);
  }
}
/** Mobile menu only
===================================*/
@media all and (max-width: 61.9375em) {
  /* Prevents font-boosting of menu items on android */
  #primary-menu * {
    max-height: 999999px;
  }

  #wrap {
    margin-top: 63px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    background-color: #FFF;
  }

  #primary-menu {
    top: 0;
    right: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #ffffff;
    width: 70%;
    z-index: -1;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    padding-top: 63px;
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.2), -1px 0 15px rgba(0, 0, 0, 0.15);
    /* right side menu */
  }
  #primary-menu[aria-expanded=false] {
    right: -70%;
  }
  #primary-menu[aria-expanded=true] {
    right: 0;
  }

  .topnav {
    padding-top: 9px;
    border-top: 1px solid #F0F4F6;
  }

  .menu-mobile-visible #wrap {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }

  /* ---- reset the mega menu styles ---- */
  .megamenu__panel .container {
    width: auto;
    max-width: unset;
  }

  .megamenu__panel__image {
    display: none;
  }

  .megamenu__panel__header {
    display: none;
  }

  .menu-item__link__description {
    display: none;
  }

  .menu-item__link__img {
    display: none;
  }

  .menu-item--visible-desktop {
    display: none;
  }

  .megamenu__panel__content__description {
    background-color: #F0F4F6;
    padding: 8px;
    margin: -8px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  /* ---- all level menu items ---- */
  .megamenu,
.topnav ul {
    width: 100%;
  }

  .menu-item {
    position: relative;
    float: none;
    width: 100%;
    text-align: left;
  }

  .menu-item__link {
    min-height: 40px;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    color: #1D1E26;
  }

  .menu-search-field {
    padding-left: 18px;
    padding-right: 18px;
  }

  .megamenu__column__title {
    line-height: 40px;
    margin-left: -27px;
  }
  .megamenu__column__title.empty {
    display: none;
  }

  .megamenu .menu-item-depth1 {
    padding-left: 18px;
    padding-right: 18px;
  }
  .megamenu .menu-item-depth1 > .menu-item__link {
    text-align: left;
    position: relative;
    padding-left: 27px;
  }
  .megamenu .menu-item-depth1 > .menu-item__link:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    background-color: #ffffff;
  }
  .megamenu .menu-item-depth1 > .menu-item__link:after {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    background: transparent;
    border-bottom: 2px solid #1D1E26;
    border-right: 2px solid #1D1E26;
    transform-origin: 66.66% 66.66%;
    margin: 0 auto;
    left: 1px;
    top: calc(50% - 8px);
    transition: top 400ms ease, transform 400ms ease;
    transform: rotate(45deg);
  }
  .megamenu .menu-item-depth1 > .menu-item__link[aria-pressed=false]:after {
    transform: rotate(45deg) translate(-1px, -1px);
  }
  .megamenu .menu-item-depth1 > .menu-item__link[aria-pressed=true]:after {
    transform: rotate(-135deg);
  }
  .megamenu .menu-item-depth1 > .menu-item__link:hover, .megamenu .menu-item-depth1 > .menu-item__link:focus, .megamenu .menu-item-depth1 > .menu-item__link[aria-pressed=true] {
    color: #571E25;
  }
  .megamenu .menu-item-depth1 > .menu-item__link:hover:after, .megamenu .menu-item-depth1 > .menu-item__link:focus:after, .megamenu .menu-item-depth1 > .menu-item__link[aria-pressed=true]:after {
    border-color: #571E25;
  }
  .megamenu .menu-item-depth1 > .menu-item__link--sale {
    padding-left: 0;
    color: #A11219;
  }
  .megamenu .menu-item-depth1 > .menu-item__link--sale:before {
    content: none;
  }
  .megamenu .menu-item-depth1 > .menu-item__link--sale:after {
    content: none;
  }

  .menu-item.menu-current-item > .menu-item__link {
    background-color: #6A6D73;
    color: #FFF;
  }

  /* ----  ---- */
  .megamenu__column {
    width: 100%;
    padding-left: 27px;
  }

  .megamenu__column__title {
    line-height: normal;
    text-transform: uppercase;
    font-weight: 400;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    letter-spacing: 0.25em;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  /* ---- First level menu ---- */

  /* ---- Depth differences ---- */

  /* ---- Transitions ---- */

  /* Menu open */
}
@media screen and (max-width: 61.9375em) and (min-width: 62em) {
  .megamenu__column__title {
    font-size: 1.5rem;
  }
}
@media all and (max-width: 61.9375em) {
  .megamenu__submenu {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media all and (max-width: 61.9375em) {
  .megamenu__submenu .menu-item__link {
    line-height: 1.33;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-item-depth1 > .menu-item__link {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-item-depth3 > .menu-item__link {
    padding-left: 18px;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-item-depth4 > .menu-item__link {
    padding-left: 32px;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-item-depth5 > .menu-item__link {
    padding-left: 45px;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-item-depth6 > .menu-item__link {
    padding-left: 58px;
  }
}
@media all and (max-width: 61.9375em) {
  #primary-menu {
    transition: left 350ms ease, right 350ms ease;
  }
}
@media all and (max-width: 61.9375em) {
  #wrap {
    transition: transform 350ms ease;
  }
}
@media all and (max-width: 61.9375em) {
  #primary-menu .container {
    transition: transform 350ms ease, opacity 350ms ease;
  }
}
@media all and (max-width: 61.9375em) {
  .menu-mobile-visible #primary-menu {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@media all and (max-width: 61.9375em) {
  .menu-mobile-visible #primary-menu .container {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
#mobile-menu-button {
  display: block;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #6A6D73;
  outline: none;
  border: none;
  padding: 0;
  text-indent: -9999px;
  position: fixed;
  top: 0;
  right: 0;
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-button__inner {
  display: block;
  width: 36px;
  height: 36px;
  margin-top: 2px;
  position: relative;
}

.mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
  height: 1.25px;
  background-color: #ffffff;
  left: 6px;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
  position: absolute;
  display: block;
  right: 6px;
}

.mobile-menu-button-top {
  top: 8px;
}

.mobile-menu-button-middle {
  top: 17px;
}

.mobile-menu-button-bottom {
  top: 26px;
}

/* animations active */
[aria-pressed=false] .mobile-menu-button-top {
  -webkit-animation-name: mobileMenuBtnTopClose;
  animation-name: mobileMenuBtnTopClose;
}

[aria-pressed=false] .mobile-menu-button-middle {
  -webkit-animation-name: mobileMenuBtnMiddleClose;
  animation-name: mobileMenuBtnMiddleClose;
}

[aria-pressed=false] .mobile-menu-button-bottom {
  -webkit-animation-name: mobileMenuBtnBottomClose;
  animation-name: mobileMenuBtnBottomClose;
}

/* Close button */
[aria-pressed=true] .mobile-menu-button-top {
  -webkit-animation-name: mobileMenuBtnTopOpen;
  animation-name: mobileMenuBtnTopOpen;
}

[aria-pressed=true] .mobile-menu-button-middle {
  -webkit-animation-name: mobileMenuBtnMiddleOpen;
  animation-name: mobileMenuBtnMiddleOpen;
}

[aria-pressed=true] .mobile-menu-button-bottom {
  -webkit-animation-name: mobileMenuBtnBottomOpen;
  animation-name: mobileMenuBtnBottomOpen;
}

@-webkit-keyframes mobileMenuBtnTopClose {
  0% {
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnTopClose {
  0% {
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnTopOpen {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@keyframes mobileMenuBtnTopOpen {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@-webkit-keyframes mobileMenuBtnBottomClose {
  0% {
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnBottomClose {
  0% {
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@keyframes mobileMenuBtnBottomOpen {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
  0% {
    opacity: 0;
  }
  50%, 70%, 100% {
    opacity: 1;
  }
}
@keyframes mobileMenuBtnMiddleClose {
  0% {
    opacity: 0;
  }
  50%, 70%, 100% {
    opacity: 1;
  }
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
  0% {
    opacity: 1;
  }
  50%, 70%, 100% {
    opacity: 0;
  }
}
@keyframes mobileMenuBtnMiddleOpen {
  0% {
    opacity: 1;
  }
  50%, 70%, 100% {
    opacity: 0;
  }
}
/** Desktop menu only
===================================*/
@media screen and (min-width: 62em) {
  #mobile-menu-button {
    display: none;
  }
}
.menu-search-field {
  display: flex;
  align-items: center;
}
.menu-search-field .field-element--text {
  position: relative;
  flex: 0 1 140px;
  max-width: 140px;
  margin: 0;
}
@media screen and (min-width: 62em) {
  .menu-search-field .field-element--text {
    display: none;
  }
}
@media screen and (min-width: 87.5em) {
  .menu-search-field .field-element--text {
    display: block;
  }
}
.menu-search-field .field-element--text:before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  right: 50%;
  bottom: 0;
  height: 1px;
  background-color: #4A4B51;
  transition: left 250ms ease, right 250ms ease;
}
.menu-search-field .field-element--text:hover:before {
  left: 5px;
  right: 0.25em;
}
.menu-search-field .field-element--text:focus-within:before {
  left: 5px;
  right: 0.25em;
}
.menu-search-field .field-element--text input {
  background: transparent;
  border: none;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.5rem;
  padding: 5px 0 4px 5px;
  line-height: 1.2;
  width: 100%;
}
.menu-search-field .field-element--text input:focus {
  outline: 1px dashed #9c9c9c;
  outline-offset: -3px;
}
@supports (display: block) {
  .menu-search-field .field-element--text input:focus {
    /*
        supports / focus within hack
        IE doesn't support focus within
        It also doesn't support @supports
    */
    outline: none;
  }
}
.menu-search-field button {
  flex: 0 0 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #ffffff;
  border: none;
  cursor: pointer;
  padding: 0;
}
.menu-search-field button svg {
  display: block;
}
.menu-search-field button:hover svg, .menu-search-field button:focus svg {
  opacity: 1;
}
@media screen and (min-width: 62em) {
  .menu-search-field .menu-search-field__button--end {
    display: none;
  }
}
@media screen and (min-width: 87.5em) {
  .menu-search-field .menu-search-field__button--end {
    display: block;
  }
}
.menu-search-field .menu-search-field__button--end svg {
  opacity: 0.5;
}
.menu-search-field .field-element--text:focus-within + .menu-search-field__button--end svg {
  opacity: 1;
}
@media screen and (min-width: 87.5em) {
  .menu-search-field.shrink-search-field .field-element--text {
    display: none;
  }
}
@media screen and (min-width: 87.5em) {
  .menu-search-field.shrink-search-field .menu-search-field__button--end {
    display: none;
  }
}

/** Mobile menu only
===================================*/
@media all and (max-width: 61.9375em) {
  .menu-search-field .menu-search-field__button--start {
    flex: 0 0 27px;
    height: 45px;
  }
  .menu-search-field .menu-search-field__button--start svg {
    opacity: 1;
  }
  .menu-search-field .field-element--text {
    flex: 1 1 auto;
    max-width: calc(100% - 45px);
  }
  .menu-search-field .field-element--text input {
    padding-left: 9px;
  }
  .menu-search-field .menu-search-field__button--end {
    flex: 0 0 27px;
    height: 45px;
    justify-content: flex-end;
  }
}
.related-links {
  margin-bottom: 1.5em;
  /* - Depth 1 - */
  /* - Depth 2 - */
}
.related-links__title {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: 0.65em;
}
@media screen and (min-width: 62em) {
  .related-links__title {
    font-size: 1.5rem;
  }
}
.related-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /* Active / on / current */
}
.related-links ul a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #6A6D73;
  transition: all 250ms ease-in-out;
  line-height: normal;
  padding-top: 0.65em;
  padding-bottom: 0.65em;
}
.related-links ul a:before, .related-links ul a:after {
  content: "";
  position: absolute;
  bottom: 0.5em;
  left: 0;
  right: 0;
  height: 1px;
  transition: left 250ms ease-in-out, right 250ms ease-in-out, background-color 250ms ease-in-out;
}
.related-links ul a:before {
  background-color: #F0F4F6;
}
.related-links ul a:after {
  left: 50%;
  right: 50%;
}
.related-links ul a:hover, .related-links ul a:focus {
  color: #571E25;
}
.related-links ul a:hover:after, .related-links ul a:focus:after {
  background-color: #571E25;
  left: 0;
  right: 0;
}
.related-links ul li.current-item > a {
  color: #0D0800;
}
.related-links ul li.current-item > a:before {
  background-color: #0D0800;
  transition: left 250ms ease-in-out, right 250ms ease-in-out, background-color 100ms ease-in-out;
}
.related-links ul li.current-item > a:after {
  background-color: #364896;
  transition: left 250ms 100ms ease-in-out, right 250ms 100ms ease-in-out, background-color 250ms ease-in-out;
}
.related-links ul li.current-item > a:hover, .related-links ul li.current-item > a:focus {
  color: #364896;
}
.related-links ul li.current-item > a:hover:before, .related-links ul li.current-item > a:focus:before {
  background-color: #F0F4F6;
}
.related-links .depth1 li {
  display: block;
}
.related-links .depth2 {
  margin-left: 0.5em;
}

* + .archive-links {
  margin-top: 20px;
}

.archive-links__title .section-title {
  margin-bottom: 0;
}

.archive-links__list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  line-height: normal;
}
.archive-links__list__item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.archive-links__list__item__link {
  display: block;
  color: #7f7f7f;
  text-decoration: none;
  transition: transform 250ms ease-in-out;
}
.archive-links__list__item__link:hover, .archive-links__list__item__link:focus, .archive-links__list__item__link:active {
  color: #1B244A;
}
.archive-links__list__item__link:after {
  content: ">";
}
.archive-links__list-depth0 {
  margin-bottom: 16px;
  border-left: 3px solid #9c9c9c;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.archive-links__list-depth0 > li {
  font-size: inherit;
  margin-top: 0;
  margin-left: 16px;
}
.archive-links__list-depth1 {
  margin-top: 0;
  list-style-type: none;
  font-size: 90%;
}
@media screen and (min-width: 35em) {
  .archive-links__list-depth1 {
    font-size: 1.5rem;
  }
}
.archive-links__list-depth1 > li {
  margin-left: 16px;
}
.archive-links__list-depth1 > li > a {
  display: inline-block;
}
.archive-links__list-depth2 {
  margin-left: 16px;
  font-size: 1.2rem;
}
@media screen and (min-width: 35em) {
  .archive-links__list-depth2 {
    font-size: 90%;
  }
}

/* ---- Forms ---- */
/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
  position: relative;
}

.field-element-attach-wrapper__button {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 0;
  background: #1B244A;
  border: none;
  outline: none;
  z-index: 1;
  cursor: pointer;
  min-width: 45px;
}

.field-element-attach-wrapper__button svg {
  vertical-align: middle;
}

.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
  background-color: #121318;
}

.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
  padding-right: 55px;
}

/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
  background-color: #E9EFF2;
}

.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
  background-color: #b8b8b8;
}

.field-element-pill-wrapper {
  display: flex;
}
.field-element-pill-wrapper:focus-within {
  outline: #6A6D73 auto 5px;
}
.field-element-pill-wrapper > *:first-child {
  flex: 1 1 auto;
  max-width: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.field-element-pill-wrapper > *:first-child input,
.field-element-pill-wrapper > *:first-child select,
.field-element-pill-wrapper > *:first-child textarea {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.field-element-pill-wrapper > *:first-child input:focus,
.field-element-pill-wrapper > *:first-child select:focus,
.field-element-pill-wrapper > *:first-child textarea:focus {
  outline: 1px dashed #b8b8b8;
}
.field-element-pill-wrapper > *:last-child {
  flex: 0 0 auto;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: calc( 1em * 1.2 + 8px);
}
.field-element-pill-wrapper .field-element--hidden-label + input[type=submit],
.field-element-pill-wrapper .field-element--hidden-label + button[type=submit] {
  margin-top: 0;
}
@media screen and (max-width: 30em) {
  .field-element-pill-wrapper input[type=submit],
.field-element-pill-wrapper button[type=submit] {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.field-element {
  margin-bottom: 1.5rem;
}

.field-element--mb0 {
  margin-bottom: 0;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a29999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a29999;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a29999;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #a29999;
}

.field-element--dropdown--placeholder .field-input select {
  color: #a29999;
}

/* ---- Auto-fill color ---- */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #E9EFF2;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #E9EFF2 inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: #6A6D73 auto 5px;
  background-color: #E9EFF2;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px #E9EFF2 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* All form elements with preceding icon */
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox,
.field-element--money .field-input .textbox,
.field-element--timepicker .field-input .textbox {
  padding-left: 52px;
}

/* Clearfix all field-elements */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

/* ---- form colour varients ---- */
/* -- default - grey -- */
.field-input input,
.field-input textarea,
.field-input select {
  border: 1px solid #E9EFF2;
  background-color: #F0F4F6;
  border-radius: 4px;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
  outline: #6A6D73 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
  background-color: #ffffff;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type=checkbox] + label,
.fieldset-input.fieldset-input--disabled input[type=checkbox] + label,
.field-element--disabled input[type=radio] + label,
.fieldset-input.fieldset-input--disabled input[type=radio] + label {
  cursor: not-allowed;
}

/* ---- Labels ---- */
.field-label,
.fieldset__legend {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 8px;
  line-height: 1.2;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element--hidden-label .field-label.focusable:active, .field-element--hidden-label .field-label.focusable:focus,
.field-element--hidden-label .fieldset__legend.focusable:active,
.field-element--hidden-label .fieldset__legend.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Required ---- */
.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0.3em;
  margin-bottom: 0;
}

/* ---- Field errors ---- */
.field-element.field-element--error .field-input .textbox,
.field-element.field-element--error .field-input select {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning .field-input .textbox,
.field-element.field-element--warning .field-input select {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

.field-error__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.field-error__list__item {
  color: #ffffff;
  background: #9E0E0B;
  padding: 10px 20px;
  margin: 11px 0;
  border-left: 6px solid #C32C2A;
}

/* ---- Clearable fields ---- */
.field-clearable__wrap {
  position: relative;
}

.field-clearable__clear {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: #D4D7DE;
  position: absolute;
  right: 6px;
  top: 9px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: top -1152px left 3px;
  width: 22px;
  height: 22px;
}

.field-clearable__clear:hover {
  background-position: top -1208px left 3px;
}

/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before, .daterangepicker.opensright:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c9c9c;
  border-left: 8px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.field-element--totalselector__dropdown:after, .daterangepicker.opensright:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

.field-element--colorpicker .textbox.colorpicker {
  padding: 2px 8px;
  height: 40px;
  width: 48px;
}

.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
  position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_datepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #9c9c9c;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #9c9c9c;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #E9EFF2;
}

.daterangepicker .calendar-table {
  border: 1px solid #b8b8b8;
  padding: 4px;
  border-radius: 4px;
  background: #ffffff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #6A6D73;
  background: #F0F4F6;
  border-color: #E9EFF2;
}

.daterangepicker td.off.in-range {
  background-color: #F0F4F6;
}

.daterangepicker td.off.active {
  background-color: #7f7f7f;
  color: #ffffff;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #6A6D73;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #3d51a7;
}

.daterangepicker td.in-range {
  background: #445bba;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #1B244A;
  color: #ffffff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #9c9c9c;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #9c9c9c;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
  padding: 1px 2px;
}

.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-image: url(../assets/icon-system/icon_form_arrow-right.svg);
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-image: url(../assets/icon-system/icon_form_arrow-left.svg);
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F0F4F6;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  color: #4A4B51;
  display: block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin: 0 0 4px 0;
  padding: 0 16px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #BCAD98;
  background-color: #F0F4F6;
}

.daterangepicker .input-mini:focus {
  outline: 4px auto #BCAD98;
  outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #4A4B51;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #BCAD98;
  color: #ffffff;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #a89479;
  color: #ffffff;
}

.daterangepicker .cancelBtn {
  background-color: #b8b8b8;
  color: #0D0800;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #9f9f9f;
  color: #0D0800;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #E9EFF2;
  border: 1px solid #F0F4F6;
  color: #0D0800;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #1B244A;
  border: 1px solid #1B244A;
  color: #ffffff;
}

@supports (display: grid) {
  .range_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 160px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker.single .calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
.daterangepicker.single .calendar {
    float: left;
  }

  .daterangepicker .calendar.right {
    margin-left: 0;
  }

  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }

  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }

  .daterangepicker .ranges,
.daterangepicker .calendar {
    float: left;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
  position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_datetimepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* fieldset styles */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.field-element--checkboxlist--columns .field-element__input-set {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
}

/* Hide checkbox and radio field element) */
.field-element input[type=checkbox],
.field-element input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element input[type=checkbox].focusable:active, .field-element input[type=checkbox].focusable:focus,
.field-element input[type=radio].focusable:active,
.field-element input[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.field-element input[type=checkbox] + label,
.field-element input[type=radio] + label,
.field-element--small input[type=checkbox] + label,
.field-element--small input[type=radio] + label {
  padding-left: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* -- Field set pseudo elements -- */
.field-element input[type=checkbox] + label:before,
.field-element input[type=radio] + label:before,
.field-element--small input[type=checkbox] + label:before,
.field-element--small input[type=radio] + label:before {
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 4px;
  margin-left: -32px;
  border: 1px solid #E9EFF2;
  background-color: #F0F4F6;
}

/* Radio button (pseudo element) */
.field-element input[type=radio] + label:before,
.field-element--small input[type=radio] + label:before {
  border-radius: 100%;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type=checkbox]:checked + label:after,
.field-element input[type=radio]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 6px;
}

/* Checkbox box checked (pseudo element) */
.field-element input[type=checkbox]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after {
  content: " ";
  background-image: url(../assets/icon-system/icon_form_tick-md.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 24px;
  height: 24px;
}

/* Radio dot (pseudo element) */
.field-element input[type=radio]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #4A4B51;
}

/* White */
.field-element--white input[type=checkbox] + label:before,
.field-element--white input[type=radio] + label:before {
  background-color: #ffffff;
}

/* input focus state */
.fieldset--multiradio:focus-within {
  outline: #E9EFF2 dashed 1px;
}

.field-element input[type=checkbox]:focus + label {
  outline: #6A6D73 auto 5px;
  outline-offset: -3px;
}

/* ------------------------
Fieldsets styles where
the label is not a sibling of the input
the label wraps the input
--------------------------- */
.checkbox__label--wrap {
  position: relative;
  cursor: pointer;
  display: block;
}
.checkbox__label--wrap .pseudo-input {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 4px;
  margin-left: -32px;
  border: 1px solid #E9EFF2;
  background-color: #F0F4F6;
  margin-left: 0;
}
.checkbox__label--wrap .pseudo-input:before {
  content: none;
}
.checkbox__label--wrap .label-body {
  padding-left: 0.2em;
}
.checkbox__label--wrap input[type=checkbox]:checked + .pseudo-input:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(../assets/icon-system/icon_form_tick-md.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 24px;
  height: 24px;
}

.radio__label--wrap {
  position: relative;
  cursor: pointer;
  display: block;
}
.radio__label--wrap .pseudo-input {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 4px;
  margin-left: -32px;
  border: 1px solid #E9EFF2;
  background-color: #F0F4F6;
  margin-left: 0;
  border-radius: 100%;
}
.radio__label--wrap .pseudo-input:before {
  content: none;
}
.radio__label--wrap .label-body {
  padding-left: 0.2em;
}
.radio__label--wrap input[type=radio]:checked + .pseudo-input:before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #4A4B51;
}

/* ---- Field errors ---- */
.field-element.field-element--error input[type=checkbox] + label:before,
.field-element.field-element--error input[type=radio] + label:before {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning input[type=checkbox] + label:before,
.field-element.field-element--warning input[type=radio] + label:before {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

/* ---- Field errors ---- */
.field-element.field-element--error label {
  color: #9E0E0B;
}
.field-element.field-element--error input[type=checkbox] + label:before,
.field-element.field-element--error input[type=radio] + label:before {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}
.field-element.field-element--error .pseudo-input {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning label {
  color: #A72D00;
}
.field-element.field-element--warning input[type=checkbox] + label:before,
.field-element.field-element--warning input[type=radio] + label:before {
  background-color: #ffebe4;
  border-color: #ffddd0;
}
.field-element.field-element--warning .pseudo-input {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤";
  /* Generic currency sign */
  position: absolute;
  display: block;
  left: 2px;
  top: calc(50% - 14px);
  color: #4A4B51;
  border-right: 1px solid #9c9c9c;
  font-weight: bold;
  font-size: 1.8rem;
  width: 40px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$";
  /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£";
  /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥";
  /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹";
  /* Indian rupee */
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #4A4B51;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #BCAD98;
  font-weight: bold;
}

.field-element--range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

.field-element--range input[type=range]:focus {
  outline: none;
}

.field-element--range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
  margin-top: -14px;
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}

.field-element--range input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

.field-element--range input[type=range]:focus::-webkit-slider-thumb {
  background: #BCAD98;
  box-shadow: 2px 2px 4px #4A4B51;
}

.field-element--range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
}

.field-element--range input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

.field-element--range input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
}

.field-element--range input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

.field-element--range input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

.field-element--range input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  .field-element--range input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input select {
  -moz-appearance: none;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg), url(../assets/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-input select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-input select::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
  padding: 8px;
  background-image: none;
}

.field-element--select--multiple option {
  padding: 8px;
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #4A4B51;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #BCAD98;
  font-weight: bold;
}

.field-element--range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

.field-element--range input[type=range]:focus {
  outline: none;
}

.field-element--range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
  margin-top: -14px;
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}

.field-element--range input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

.field-element--range input[type=range]:focus::-webkit-slider-thumb {
  background: #BCAD98;
  box-shadow: 2px 2px 4px #4A4B51;
}

.field-element--range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
}

.field-element--range input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

.field-element--range input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #323236;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4A4B51;
  cursor: pointer;
}

.field-element--range input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

.field-element--range input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

.field-element--range input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  .field-element--range input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  padding: 12px 16px;
  -webkit-appearance: none;
  line-height: 1.4;
}

.field-input select {
  padding-right: 48px;
}

/* time picker */
.field-element--timepicker .field-input {
  position: relative;
}

.field-element--timepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_timepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Timepicker modal */
.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
  z-index: 9999 !important;
}

.ui-timepicker-standard {
  background-color: #ffffff;
  border: 1px solid #E9EFF2;
  display: block;
  margin: 4px 0 0;
  padding: 6px;
  list-style: none outside none;
  border-radius: 4px;
}

.ui-timepicker-standard a {
  color: #0D0800;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #E9EFF2;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 4px;
}

.ui-timepicker-hidden {
  display: none;
}

.field-element--totalselector {
  position: relative;
}

.field-element--totalselector .field-input .total-selector__output {
  cursor: pointer;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg), url(../assets/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: 2px;
  border-radius: 4px;
  z-index: 800;
  border: 1px solid #9c9c9c;
  display: none;
  white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  outline: none;
  background: none;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #6A6D73;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
  background-image: url(../assets/icon-system/icon_form_minus.svg);
  background-position: left 8px -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
  background-image: url(../assets/icon-system/icon_form_plus.svg);
  background-position: right 8px -1 center;
}

.total-selector__dropdown__field__button:focus {
  color: #6A6D73;
  border-color: #6A6D73;
  background-color: #F0F4F6;
}

.total-selector__dropdown__field__button:hover {
  color: #6A6D73;
  border-color: #6A6D73;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
  opacity: 0.5;
  pointer-events: none;
  color: #6A6D73;
  border-color: #b8b8b8;
}

.total-selector__dropdown__field__total {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 4px 6px;
  width: 50px;
  text-align: center;
  font-size: 1.8rem;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title {
  margin: 0;
  font-size: 1.6rem;
}

.total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: 1.2rem;
}

.field-element--totalselector__fields {
  display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
  text-align: right;
  padding-top: 10px;
}

/* BP Extra small */
@media screen and (min-width: 35em) {
  .field-element--totalselector__dropdown {
    right: auto;
    min-width: 320px;
  }

  .total-selector__dropdown__field__labels__helper {
    font-size: 1.5rem;
    line-height: 1;
  }

  .total-selector__dropdown__field {
    margin: 0 0 24px;
  }
}
/* BP Small */
@media screen and (min-width: 48em) {
  /* Don't really need this close button above tablet */
  .total-selector__dropdown__close__button-wrap {
    display: none;
  }
}
.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]:focus {
  outline: #6A6D73 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

.field-element--upload,
.field-element--fileupload {
  /* Chrome, Edge & Safari */
}
.field-element--upload input[type=file]::file-selector-button,
.field-element--fileupload input[type=file]::file-selector-button {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: #1B244A;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  padding: 12px 27px;
  padding-left: 15px;
  padding-right: 15px;
}
.field-element--upload input[type=file]::file-selector-button:hover, .field-element--upload input[type=file]::file-selector-button:focus, .field-element--upload input[type=file]::file-selector-button:active,
.field-element--fileupload input[type=file]::file-selector-button:hover,
.field-element--fileupload input[type=file]::file-selector-button:focus,
.field-element--fileupload input[type=file]::file-selector-button:active {
  background-color: #0d1225;
  color: #ffffff;
}
.field-element--upload input[type=file]::file-selector-button svg path,
.field-element--fileupload input[type=file]::file-selector-button svg path {
  fill: #ffffff;
}
.field-element--upload input[type=file]::file-selector-button:after,
.field-element--fileupload input[type=file]::file-selector-button:after {
  content: " >";
}
.field-element--upload input[type=file]::-webkit-file-upload-button,
.field-element--fileupload input[type=file]::-webkit-file-upload-button {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: #1B244A;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  padding: 12px 27px;
  padding-left: 15px;
  padding-right: 15px;
}
.field-element--upload input[type=file]::-webkit-file-upload-button:hover, .field-element--upload input[type=file]::-webkit-file-upload-button:focus, .field-element--upload input[type=file]::-webkit-file-upload-button:active,
.field-element--fileupload input[type=file]::-webkit-file-upload-button:hover,
.field-element--fileupload input[type=file]::-webkit-file-upload-button:focus,
.field-element--fileupload input[type=file]::-webkit-file-upload-button:active {
  background-color: #0d1225;
  color: #ffffff;
}
.field-element--upload input[type=file]::-webkit-file-upload-button svg path,
.field-element--fileupload input[type=file]::-webkit-file-upload-button svg path {
  fill: #ffffff;
}
.field-element--upload input[type=file]::-webkit-file-upload-button:after,
.field-element--fileupload input[type=file]::-webkit-file-upload-button:after {
  content: " >";
}
.field-element--upload input[type=file]:hover::-webkit-file-upload-button,
.field-element--fileupload input[type=file]:hover::-webkit-file-upload-button {
  background-color: #1B244A;
  border-color: #1B244A;
}
.field-element--upload input[type=file]:focus::-webkit-file-upload-button,
.field-element--fileupload input[type=file]:focus::-webkit-file-upload-button {
  background-color: #1B244A;
  outline: none;
}
.field-element--upload input[type=file]:active::-webkit-file-upload-button,
.field-element--fileupload input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #1B244A;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #E9EFF2;
  border-color: #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #E9EFF2;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--fileupload input {
  line-height: 35px;
  width: 100%;
}

.submit-bar {
  text-align: right;
  background: #F0F4F6;
  padding: 16px;
  margin: 16px 0;
}

.sidebar .submit-bar {
  padding: 12px 0;
}

.submit-bar a {
  margin-right: 20px;
}

.submit-bar--bleed-left {
  position: relative;
}
.submit-bar--bleed-left:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #F0F4F6;
}

.submit-bar--bleed-right {
  position: relative;
}
.submit-bar--bleed-right:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #F0F4F6;
}

.field-element--button .button {
  padding-top: 16px;
  padding-bottom: 16px;
}

.site-search-form {
  margin: 0 0 40px;
}

.site-search-form--header {
  margin: 0;
}

.search-result {
  border-top: 1px solid #9c9c9c;
  margin: 20px 0;
  padding: 20px 0;
}

.search-result p:last-child {
  margin-bottom: 0;
}

.g-recaptcha {
  padding-bottom: 15px;
}

.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  padding: 4px 8px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F0F4F6;
  transition: border-color 250ms ease-in-out, background-color 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #E9EFF2;
  border-color: #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #E9EFF2;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #6A6D73 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #E9EFF2;
  border-color: #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #E9EFF2;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--upload-inline {
  background-color: #F0F4F6;
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.field-element--upload-inline label {
  display: block;
  cursor: pointer;
  font-weight: bold;
}
.field-element--upload-inline label:after {
  content: "select";
  font-weight: normal;
  background: #1B244A;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  transition: background-color 250ms ease-in-out;
}
.field-element--upload-inline label:hover:after {
  background-color: #070912;
}
.field-element--upload-inline input:focus + label,
.field-element--upload-inline input:active + label {
  outline: #6A6D73 auto 5px;
}
.field-element--upload-inline input[type=file] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.sproutforms-message-errors {
  padding: 15px;
  background-color: #6A6D73;
  color: #ffffff;
  border-radius: 3px;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1em;
  background-color: #A72D00;
}
.sproutforms-message-errors > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .sproutforms-message-errors {
    padding: 21px;
  }
}
@media screen and (min-width: 48em) {
  .sproutforms-message-errors {
    padding: 45px;
  }
}
@media screen and (min-width: 62em) {
  .sproutforms-message-errors {
    font-size: 2.1rem;
  }
}

.sproutforms-message-success {
  padding: 15px;
  background-color: #6A6D73;
  color: #ffffff;
  border-radius: 3px;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1em;
  background-color: #2D4A13;
}
.sproutforms-message-success > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .sproutforms-message-success {
    padding: 21px;
  }
}
@media screen and (min-width: 48em) {
  .sproutforms-message-success {
    padding: 45px;
  }
}
@media screen and (min-width: 62em) {
  .sproutforms-message-success {
    font-size: 2.1rem;
  }
}