.table--content-standard {
    width: 100%;
    margin: 2rem 0;
}

.table--content-standard caption {
    text-align: left;
    padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
    font-weight: bold;
    background-color: $color-grey-02;
    font-size: 1.7rem;
}

.table--content-standard td {
    background-color: $color-white;
}

.table--content-standard th,
.table--content-standard td {
    border: 1px solid $color-grey-02;
    padding: 12px 15px;
    text-align: left;
}
