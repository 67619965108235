#mobile-menu-button {
    display: block;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: $color-grey-06;
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    position: fixed;
    top: 0;
    right: 0;
    width: 63px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu-button__inner {
    display: block;
    width: 36px;
    height: 36px;
    margin-top: 2px;
    position: relative;
}

.mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 1.25px;
    background-color: $color-white;
    left: 6px;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
}
.mobile-menu-button-top {
    top: 8px;
}
.mobile-menu-button-middle {
    top: 17px;
}
.mobile-menu-button-bottom {
    top: 26px;
}

/* animations active */
[aria-pressed="false"] .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopClose;
    animation-name: mobileMenuBtnTopClose;
}
[aria-pressed="false"] .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleClose;
    animation-name: mobileMenuBtnMiddleClose;
}
[aria-pressed="false"] .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomClose;
    animation-name: mobileMenuBtnBottomClose;
}

/* Close button */
[aria-pressed="true"] .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopOpen;
    animation-name: mobileMenuBtnTopOpen;
}
[aria-pressed="true"] .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleOpen;
    animation-name: mobileMenuBtnMiddleOpen;
}
[aria-pressed="true"] .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomOpen;
    animation-name: mobileMenuBtnBottomOpen;
}



@-webkit-keyframes mobileMenuBtnTopClose {
    0% {
        -webkit-transform: translate(0, 9px) rotate(45deg);
        transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
        -webkit-transform: translate(0, 9px);
        transform: translate(0, 9px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}



@keyframes mobileMenuBtnTopClose {
    0% {
        -webkit-transform: translate(0, 9px) rotate(45deg);
        transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
        -webkit-transform: translate(0, 9px);
        transform: translate(0, 9px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
@-webkit-keyframes mobileMenuBtnTopOpen {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50%, 70% {
        -webkit-transform: translate(0, 9px);
        transform: translate(0, 9px);
    }
    100% {
        -webkit-transform: translate(0, 9px) rotate(45deg);
        transform: translate(0, 9px) rotate(45deg);
    }
}
@keyframes mobileMenuBtnTopOpen {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50%, 70% {
        -webkit-transform: translate(0, 9px);
        transform: translate(0, 9px);
    }
    100% {
        -webkit-transform: translate(0, 9px) rotate(45deg);
        transform: translate(0, 9px) rotate(45deg);
    }
}
@-webkit-keyframes mobileMenuBtnBottomClose {
    0% {
        -webkit-transform: translate(0, -9px) rotate(-45deg);
        transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
        -webkit-transform: translate(0, -9px);
        transform: translate(0, -9px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
@keyframes mobileMenuBtnBottomClose {
    0% {
        -webkit-transform: translate(0, -9px) rotate(-45deg);
        transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
        -webkit-transform: translate(0, -9px);
        transform: translate(0, -9px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50%, 70% {
        -webkit-transform: translate(0, -9px);
        transform: translate(0, -9px);
    }
    100% {
        -webkit-transform: translate(0, -9px) rotate(-45deg);
        transform: translate(0, -9px) rotate(-45deg);
    }
}
@keyframes mobileMenuBtnBottomOpen {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50%, 70% {
        -webkit-transform: translate(0, -9px);
        transform: translate(0, -9px);
    }
    100% {
        -webkit-transform: translate(0, -9px) rotate(-45deg);
        transform: translate(0, -9px) rotate(-45deg);
    }
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
    0% {
        opacity: 0;
    }
    50%, 70%, 100% {
        opacity: 1;
    }
}
@keyframes mobileMenuBtnMiddleClose {
    0% {
        opacity: 0;
    }
    50%, 70%, 100% {
        opacity: 1;
    }
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
    0% {
        opacity: 1;
    }
    50%, 70%, 100% {
        opacity: 0;
    }
}
@keyframes mobileMenuBtnMiddleOpen {
    0% {
        opacity: 1;
    }
    50%, 70%, 100% {
        opacity: 0;
    }
}

/** Desktop menu only
===================================*/
@include screen-992() {
    #mobile-menu-button {
        display: none;
    }
}
