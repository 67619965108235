
.message-block {
    margin: 0 0 1.5rem;
    padding: 1em;
    padding-left: 1.5em;
    border: 1px solid $color-grey-01;
    border-left: 4px solid $color-00;
    border-radius: 3px;

    > *:last-child {
        margin-bottom: 0;
    }

    &__title {
        color: $color-00;
    }

    &--small {
        font-size: 80%;

        .message-block__title {
            font-size: $fs-body;
        }
    }

    strong {
        color: $color-primary;
    }
}
