
.container {
    width: 88%;
    margin: 0 auto;

    /* BP small */
    @include screen-768 {
        width: calc(100vw - 90px);
    }

    /* BP xxlarge */
    @include screen-1600 {
        max-width: $container-max;
    }
}

