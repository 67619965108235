h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 1.5rem 0;

}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    font-family: $accent-font;
    font-weight: $fw-light;
    font-size: $fs-body*2.4;
    line-height: 1.1;

    @include screen-560 {
        font-size: $fs-body*4;
    }

    @include screen-768 {
        font-size: $fs-body*6;
    }
}

h2, .h2 {
    font-family: $accent-font;
    font-weight: $fw-light;
    font-size: $fs-body*1.8;
    line-height: 1.5;

    @include screen-560 {
        font-size: $fs-body*2.4;
    }

    @include screen-768 {
        font-size: $fs-body*4;
    }
}

h3, .h3 {
    font-family: $accent-font;
    font-weight: $fw-regular;
    font-size: $fs-body*1.2;

    @include screen-560 {
        font-size: $fs-body*1.4;
    }
}

h4, .h4 {
    font-family: $accent-font;
    font-weight: $fw-regular;
    font-size: $fs-body*1.1;

    @include screen-560 {
        font-size: $fs-body*1.2;
    }
}

h5, .h5 {
    font-family: $primary-font;
    font-weight: $fw-bold;
    font-size: $fs-body;
    margin-bottom: 0;

    @include screen-560 {
        font-size: $fs-body*1.2;
    }
}

h6, .h6 {
    font-family: $primary-font;
    font-weight: $fw-bold;
    font-size: $fs-body;
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 1.5rem;
}

small {
    font-size: 1.28rem;
}

.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.title-style-01 {
    @include title-style-01;
}

.title-style-02 {
    @include title-style-02;
}

.title-style-03 {
    @include title-style-03;
}

.title-style-04 {
    @include title-style-04;
}

.title-style-paragraph {
    @include title-style-paragraph;
}

.subtitle {
    @include subtitle;
}
