@mixin sproutformsMessage {
    @include box('large');
    background-color: $color-grey-06;
    color: $color-white;
    border-radius: 3px;
    text-align: center;
    font-size: $fs-body*1.2;
    margin-bottom: 1em;

    @include screen-992 {
        font-size: $fs-body*1.4;
    }
}

.sproutforms-message-errors {
    @include sproutformsMessage;
    background-color: $color-utility-alert;
}

.sproutforms-message-success {
    @include sproutformsMessage;
    background-color: $color-utility-positive;
}
