.section,
.section-regular {
    padding-top: 45px;
    padding-bottom: 45px;
}

.section-small {
    padding-top: 27px;
    padding-bottom: 27px;
}

.section-large {
    padding-top: 63px;
    padding-bottom: 63px;
}

.section-t-sm {
    padding-top: 27px;
}

.section-t0 {
    padding-top: 0;
}

.section-b0 {
    padding-bottom: 0;
}


/* BP small */
@include screen-768 {
    .section,
    .section-regular {
        padding-top: 63px;
        padding-bottom: 63px;
    }

    .section-small {
        padding-top: 36px;
        padding-bottom: 36px;
    }

    .section-large {
        padding-top: 108px;
        padding-bottom: 108px;
    }

    .section-t-sm {
        padding-top: 36px;
    }

    .section-t0 {
        padding-top: 0;
    }

    .section-b0 {
        padding-bottom: 0;
    }
}
