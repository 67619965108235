
/* for minor lists made using flex that will stack at an flexible point */
.flex-stack-list {
    margin-bottom: -5px;

    > * {
        margin-bottom: 5px;
    }
}

.flex-stack-list-sm {
    margin-bottom: -12px;

    > * {
        margin-bottom: 12px;
    }
}

.flex-stack-list-lg {
    margin-bottom: -16px;

    > * {
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 48em) { /* 768px */
    .flex-stack-list-none--sm {
        margin-bottom: 0;

        > * {
            margin-bottom: 0;
        }
    }
}

/* BP large */
@media screen and (min-width: 75em) { /* 1200px */
    .flex-stack-list-none--lg {
        margin-bottom: 0;

        > * {
            margin-bottom: 0;
        }
    }
}

