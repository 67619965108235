.social-list {
    list-style: none;
    padding: 0;
    margin: 0px;
    margin-left: $spacing--xsmall;
    margin-right: $spacing--xsmall;
    height: $spacing--large;
}

.social-list__item {
    display: inline-block;
    padding: 0 $spacing--xsmall;
}

.social-list__item a {
    color: inherit;
    display: block;
}
