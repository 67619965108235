.navigation-megamenu {
    position: relative;
}

/** Desktop and Mobile styling
===================================*/
#primary-menu,
.megamenu,
.topnav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    transition: margin $link-transition;
}

.menu-item__link {
    text-decoration: none;
    display: block;
    font-size: 1.6rem;
}

.megamenu .menu-item__link {
    white-space: normal;
    cursor: pointer;
    border: none;
    line-height: 1.5;
    background-color: transparent;
    font-family: $primary-font;
}

.megamenu__panel {
    &[aria-expanded="false"] {
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
    }

    &[aria-expanded="true"] {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        width: auto;
    }
}

.megamenu__submenu  {
    list-style-type: none;
    padding: 0;
}


/** Desktop menu only
===================================*/
@media screen and (min-width: $bp992) {

    #primary-menu {
        z-index: 1000;
    }

    .megamenu,
    .topnav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }


    /* ---- all level menu items ---- */
    .menu-item {
        position: relative;
    }

    .navigation-megamenu .menu-item {
        position: static;
    }


    /* ---- First level menu ---- */
    .menu-item-depth1 {
        text-align: center;
        flex: 0 1 auto;
    }

    .menu-item-depth1 > .menu-item__link {
        display: block;
        font-weight: $fw-regular;
        position: relative;
        z-index: 0;
        transition: color 250ms ease;
        flex: 0 0 auto;
    }

    .megamenu {
        margin-right: -21px;

        &.shrink-menu .menu-item-depth1 > .menu-item__link {
            padding: 12px 1px;
        }

        .menu-item-depth1 {
            @include screen-1200 {
                margin-left: 15px;
            }

            @include screen-1600 {
                margin-left: 45px;
            }

            > .menu-item__link {
                font-size: $fs-body*1.2;
                padding: 12px 10px;
                color: $color-primary;

                @include screen-1400 {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    top: 0;
                    bottom: 0;
                    background-color: $color-00;
                    transition: left 250ms ease, right 250ms ease;
                    z-index: -1;
                }

                &:hover,
                &:focus {
                    color: $color-white;

                    &:before {
                        left: 0;
                        right: 0;
                    }
                }

                &--sale {
                    color: #A11219;

                    &:before {
                        background-color: #A11219;
                    }
                }

                &--client {
                    color: lighten($color-01, 20%);
                    max-width: 11ch;
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            /* match the item hover state to the link hover state */
            &:hover > .menu-item__link,
            &:focus > .menu-item__link {
                color: $color-white;

                &:before {
                    left: 0;
                    right: 0;
                }
            }

            /* Current item */
            &.menu-current-item > .menu-item__link {
                color: red;
            }

            /* ancestor item */
            &.menu-current-item-ancestor > .menu-item__link {
                color: red;
            }
        }
    }

    .topnav  {
        margin-top: 12px;
        margin-bottom: 12px;

        $topnav-little-gap-sm: 9px;
        $topnav-big-gap-sm: 18px;
        $topnav-little-gap-md: 15px;
        $topnav-big-gap-md: 27px;
        $topnav-little-gap-lg: 18px;
        $topnav-big-gap-lg: 54px;

        .menu-item-depth1 {
            transition: margin 200ms ease;
            margin-left: $topnav-little-gap-sm;
            margin-right: $topnav-little-gap-sm;

            @include screen-1400() {
                margin-left: $topnav-little-gap-md;
                margin-right: $topnav-little-gap-md;
            }

            @include screen-1600 {
                margin-left: $topnav-little-gap-lg;
                margin-right: $topnav-little-gap-lg;
            }

            &:first-child {
                margin-left: 0;
                margin-right: $topnav-big-gap-sm;

                @include screen-1400 {
                    margin-right: $topnav-big-gap-md - 9px;
                }

                @include screen-1600 {
                    margin-right: $topnav-big-gap-lg - 9px;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            .menu-item__link--logout {
                display: inline-block;
                margin-bottom: 0;
                white-space: normal;
                vertical-align: middle;
                text-align: center;
                cursor: pointer;
                color: inherit;
                background: transparent;
                text-decoration: none;
                border-radius: 0;
                border: none;
                outline: none;
                font: inherit;
                line-height: inherit;
                transition:
                    background-color 250ms ease-in-out,
                    color 250ms ease-in-out,
                    border-color 250ms ease-in-out,
                    box-shadow $link-transition;
                padding: 0;
            }

            > .menu-item__link {
                font-size: $fs-body*0.8;
                text-transform: uppercase;
                color: rgba($color-grey-07, 0.75);
                letter-spacing: 0.15em;

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    bottom: 0;
                    height: 1px;
                    background-color: $color-grey-07;
                    transition: left 250ms ease, right 250ms ease;
                    z-index: -1;
                }

                &:hover,
                &:focus {
                    color: rgba($color-grey-07, 1);

                    &:before {
                        left: 0;
                        right: 0.25em;
                    }
                }

                /* Current item */
                &.menu-current-item > .menu-item__link {
                    color: #000;
                }

                /* ancestor item */
                &.menu-current-item-ancestor > .menu-item__link {
                    color: red;
                }
            }

            &.menu-item--telephone {
                margin-right: $topnav-big-gap-sm;

                @include screen-1200 {
                    margin-right: $topnav-big-gap-md;
                }

                @include screen-1600 {
                    margin-right: $topnav-big-gap-lg;
                }

                > .menu-item__link {
                    letter-spacing: 0;
                    display: flex;
                    align-items: center;

                    &:before {
                        content: none;
                    }

                    .menu-item__link__label {
                        display: block;
                        position: relative;
                        padding-left: 5px;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 50%;
                            right: 50%;
                            bottom: 0;
                            height: 1px;
                            background-color: $color-grey-07;
                            transition: left 250ms ease, right 250ms ease;
                            z-index: -1;
                        }
                    }

                    &:hover,
                    &:focus {
                        .menu-item__link__label:before {
                            left: 5px;
                            right: 0;
                        }
                    }

                }
            }

            &.menu-item--shop {
                margin-left: $topnav-big-gap-sm;
                margin-right: $topnav-big-gap-sm;

                @include screen-1200 {
                    margin-left: $topnav-big-gap-md;
                    margin-right: $topnav-big-gap-md;
                }

                @include screen-1600 {
                    margin-left: $topnav-big-gap-lg;
                    margin-right: $topnav-big-gap-lg;
                }
            }

            &.menu-item--socials {
                margin-left: 0;
            }

            .social-list__item {
                padding: 0;

                &.facebook {
                    margin-right: 18px;

                    a {
                        padding-left: 9px;
                        padding-right: 9px;
                    }
                }
            }
        }
    }


    /* ---- Megamenu ---- */
    /* Remove relative positioning for mega menu */
    .megamenumenu-mega .menu-item {
        position: static;
    }

    .menu-item.megamenu-hover .megamenu,
    .menu-item.megamenu-hold #primary-menu {
        padding: 40px 0;
        width: auto;
        height: auto;
        clip: auto;
        overflow: visible;
        margin: auto;
    }

    .menu-item.megamenu-hold #primary-menu {
        display: block !important;
        height: auto !important;
    }

    .megamenu__panel {
        left: 0;
        right: 0;
        background-color: $color-grey-01;
        text-align: left;
        top: 100%;
        position: absolute;
        border-bottom: 2px solid $color-grey-02;
        z-index: 51;
        display: flex;

        // To disable tabstops inside the mega menu.
        &[aria-expanded="false"] {
            visibility: hidden;
        }
    }

    // .megamenu__panel__inner {
    //     display: flex;
    //     align-items: flex-start;

    //     @supports(gap: 40px) {
    //         gap: 40px;
    //     }
    // }

    .megamenu__panel__image {
        display: none;
    }

    .megamenu__panel__content {
        padding-top: 45px;
        padding-bottom: 45px;
        padding-left: 4vw;
        padding-right: 4vw;

        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 100%;
        max-width: 100%;
    }

    .megamenu__panel__content__description {
        background-color: darken($color-grey-02, 5%);
        padding: $spacing*1.5 $spacing*2;
        margin-left: -$spacing*2;
        margin-bottom: $spacing*3;

    }


    .megamenu__panel__header {
        display: none;
        // margin-bottom: 27px;

        &__subtitle {
            margin-bottom: 0;
            line-height: 1.2;
            @include subtitleBlock($color-01, true);
            display: inline-block;
            margin-left: -9px;
        }
        &__title {
            margin-top: 0;
            margin-bottom: $fs-small;
            line-height: 1;
            @include title-style-03;
            font-weight: $fw-light;
            font-size: $fs-body*1.8;

            @include screen-560 {
                font-size: $fs-body*2.4;
            }

            @include screen-768 {
                font-size: $fs-body*4;
            }
        }
        &__description {
            margin-bottom: 0;
        }
    }

    /* Megamenu columns */
    #primary-menu .megamenu__panel__content__columns {
        @supports(display: grid) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        }
    }

    .megamenu__column {
        width: 31.33333333%;
        float: left;
        margin-right: 3%;

        @supports(display: grid) {
            width: auto;
            float: none;
            margin-right: none;
        }
    }

    .megamenu__column:last-child {
        margin-right: 0;
    }

    .megamenu__column__title {
        font-size: $fs-body;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        margin-bottom: 0;
    }

    /* Megamenu submenus */
    .megamenu__submenu .menu-item__link {
        padding: 5px 0;
        text-decoration: none;
    }

    .menu-item__link--image {
        display: flex;

        @supports(gap: 10px) {
            gap: 10px;
        }
    }

    .menu-item__link__img {
        flex: 0 1 auto;
        margin-right: 10px;
        object-fit: contain;
        vertical-align: top;

        @supports(gap: 10px) {
            margin-right: 0;
        }
    }

    .menu-item__link__copy {
        flex: 1 1 auto;
        max-width: 100%;
    }
    .megamenu__submenu .menu-item__link:hover .menu-item__link__title,
    .megamenu__submenu .menu-item__link:focus .menu-item__link__title,
    .megamenu__submenu .menu-item__link:active .menu-item__link__title {
        text-decoration: underline;
    }

    .menu-item__link__title {
        display: block;
        font-size: $fs-body*1.1;
        letter-spacing: 0.1em;
        font-weight: $fw-regular;

        @include screen-1400 {
            letter-spacing: 0.25em;
        }

        &:after {
            content: " >";
        }
    }
    .menu-item__link__description {
        display: block;
        letter-spacing: normal;
        font-size: $fs-body;
        color: $color-grey-06;
    }
}

@include screen-1200 {
    .topnav .menu-item-depth1 > .menu-item__link {
        letter-spacing: 0.25em;
        font-size: $fs-body;
    }

    .megamenu .menu-item-depth1 > .menu-item__link {
        font-size: $fs-body*1.4;
    }

    .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
        padding: 12px 12px;
        font-size: $fs-body*1.3;
    }

    .megamenu .menu-item-depth1 > .menu-item__link--client {
        max-width: calc(14ch + 24px);
    }

    .megamenu__panel__image {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 25%;
        max-width: 25%;
        object-fit: cover;
    }

    .megamenu__panel__content {
        padding-right: 4vw;
        padding-left: 45px;
        flex-basis: 75%;
        max-width: 75%;
    }
}

@include screen-1400 {
    .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
        padding: 12px 21px;
    }

    .megamenu.shrink-menu .menu-item-depth1 > .menu-item__link {
        font-size: $fs-body*1.4;
    }

    .megamenu .menu-item-depth1 > .menu-item__link--client {
        max-width: calc(16ch + 42px);
    }
}

@include screen-1600 {
    .megamenu .menu-item-depth1 > .menu-item__link--client {
        max-width: calc(19ch + 42px);
    }
}

/** Mobile menu only
===================================*/
@media all and (max-width: $bp991) {

    /* Prevents font-boosting of menu items on android */
    #primary-menu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: 63px;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        background-color: #FFF;
    }

    #primary-menu {
        top: 0;
        right: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: $color-white;
        width: 70%;
        z-index: -1;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        padding-top: 63px;
        box-shadow: 0 0 27px rgba(0,0,0,0.2), -1px 0 15px rgba(0,0,0,0.15);

        /* right side menu */
        &[aria-expanded="false"] {
            right: -70%;
        }

        &[aria-expanded="true"] {
            right: 0;
        }
    }

    .topnav {
        padding-top: 9px;
        border-top: 1px solid $color-grey-01;
    }

    // .menu-mobile-pos-right #primary-menu .container {
    //     -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    //     transform: translate3d(10%, 0, 0) scale(0.9);
    // }
    .menu-mobile-visible #wrap {
        -webkit-transform: translate3d(-70%, 0, 0);
        transform: translate3d(-70%, 0, 0);
    }

    /* ---- reset the mega menu styles ---- */
    .megamenu__panel .container {
        width: auto;
        max-width: unset;
    }
    .megamenu__panel__image {
        display: none;
    }

    .megamenu__panel__header {
        display: none;
    }

    .menu-item__link__description {
        display: none;
    }

    .menu-item__link__img {
        display: none;
    }

    .menu-item--visible-desktop {
        display: none;
    }

    .megamenu__panel__content__description {
        background-color: $color-grey-01;
        padding: $spacing;
        margin: -$spacing;
        margin-top: 0;
        margin-bottom: $spacing;
    }



    /* ---- all level menu items ---- */
    .megamenu,
    .topnav ul {
        width: 100%;
    }
    .menu-item {
        position: relative;
        float: none;
        width: 100%;
        text-align: left;
    }
    .menu-item__link {
        min-height: 40px;
        width: 100%;
        padding-top: 9px;
        padding-bottom: 9px;
        color: $color-primary;
    }
    .menu-search-field {
        padding-left: 18px;
        padding-right: 18px;
    }
    .megamenu__column__title {
        line-height: 40px;
        margin-left: -27px;

        &.empty {
            display: none;
        }
    }
    .megamenu .menu-item-depth1 {
        padding-left: 18px;
        padding-right: 18px;

        > .menu-item__link {
            text-align: left;
            position: relative;
            padding-left: 27px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 20px;
                background-color: $color-white;
            }

            &:after {
                content: "";
                width: 12px;
                height: 12px;
                display: block;
                position: absolute;
                background: transparent;
                border-bottom: 2px solid $color-primary;
                border-right: 2px solid $color-primary;
                transform-origin: 66.66% 66.66%;
                margin: 0 auto;
                left: 1px;
                top: calc(50% - 8px);
                transition:
                    top 400ms ease,
                    transform 400ms ease;
                transform: rotate(45deg);
            }

            &[aria-pressed="false"] {
                &:after {
                    transform: rotate(45deg) translate(-1px, -1px);
                }
            }

            &[aria-pressed="true"] {
                &:after {
                    transform: rotate(-135deg);
                }
            }

            &:hover,
            &:focus,
            &[aria-pressed="true"] {
                color: $color-01;

                &:after {
                    border-color: $color-01;
                }
            }

            &--sale {
                padding-left: 0;
                color: #A11219;

                &:before {
                    content: none;
                }

                &:after {
                    content: none;
                }
            }
        }
    }

    .menu-item.menu-current-item > .menu-item__link {
        background-color: $color-grey-06;
        color: #FFF;
    }

    /* ----  ---- */
    .megamenu__column {
        width: 100%;
        padding-left: 27px;
    }

    .megamenu__column__title {
        @include subtitle;
        margin-bottom: 0;
    }

    .megamenu__submenu {
        margin-top: 0;
        margin-bottom: 0;
    }

    /* ---- First level menu ---- */
    .megamenu__submenu .menu-item__link {
        line-height: 1.33;
    }

    /* ---- Depth differences ---- */
    .menu-item-depth1 > .menu-item__link {
        padding-left: 18px;
        padding-right: 18px;
    }
    .menu-item-depth3 > .menu-item__link  {
        padding-left: 18px;
    }
    .menu-item-depth4 > .menu-item__link  {
        padding-left: 32px;
    }
    .menu-item-depth5 > .menu-item__link  {
        padding-left: 45px;
    }
    .menu-item-depth6 > .menu-item__link  {
        padding-left: 58px;
    }

    /* ---- Transitions ---- */
    #primary-menu {
        transition:
            left 350ms ease,
            right 350ms ease;
    }
    #wrap {
        transition:
            transform 350ms ease;
    }
    #primary-menu .container {
        transition:
            transform 350ms ease,
            opacity 350ms ease;
    }

    /* Menu open */
    .menu-mobile-visible #primary-menu {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    .menu-mobile-visible #primary-menu .container {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}
