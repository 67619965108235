
.bg-white {
    background-color: $color-white;
}

.bg-lightgrey {
    background-color: $color-grey-02;

    &.container--bleed-left {
        @include bleed-left($color-grey-02);
    }

    &.container--bleed-right {
        @include bleed-right($color-grey-02);
    }
}

.bg-primary {
    background-color: $color-primary;
}

.bg-00 {
    background-color: $color-00;
}

.bg-01 {
    background-color: $color-01;
}

.bg-02 {
    background-color: $color-02;
}

.bg-03 {
    background-color: $color-03;
}

